import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import {
  Route,
} from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { CookiesProvider } from 'react-cookie'
import history from '@/historyRef'
import store from '@redux/store'
import '@/i18n'

// containers
import {
  App,
} from '@containers';

// global styles
import 'normalize.css';
import 'react-vis/dist/main.scss';
import '@/assets/scss/global.scss';
// react-slick theme
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

render(
  <Provider store={store}>
    <CookiesProvider>
      <ConnectedRouter history={history}>
        <Route path="/">
          <App />
        </Route>
      </ConnectedRouter>
    </CookiesProvider>
  </Provider>,
  document.getElementById('app')
);
