import React, { useCallback } from 'react';
import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom';
// components
import {
  TextField,
  SubmitButton
} from '@components';
import logo from '@/assets/images/logo.svg'

const ForgotPasswordSubview = (props) => {
  const {
    t,
    hidden,
    onChange,
    onSubmit,
  } = props;
  
  const onKeyPress = useCallback((e) => {
    if(e.key === "Enter") {
      onSubmit();
    }
  })

  return (
    <div className={ "forgot-password" + (hidden ? " hidden" : "") }>
      <img
        className="logo"
        src={logo}
        alt="logo"
      />
      <div className="title">
        { t('forgotPassword') }
      </div>
      <div className="description">
        { t('forgotPasswordDesc') }
      </div>
      <TextField
        id="account"
        group="forgotPassword"
        title={ t('account') }
        placeholder={ t('accountPlaceholder') }
        onChange={ onChange }
        onKeyPress={ onKeyPress }
      />
      <SubmitButton onSubmit={ onSubmit }>
        { t('submit') }
      </SubmitButton>
      <div className="link-wrapper">
        <Link to="/login">
          { t('backToLogin') }
        </Link>
      </div>
    </div>
  )
}

export default withTranslation('accountTranslation')(ForgotPasswordSubview);
