import React from 'react';
import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom';
// components
import {
  TextField,
  SubmitButton,
} from '@components';
import logo from '@/assets/images/logo.svg'

const RegisterSubview = (props) => {
  const {
    t,
    hidden,
    onChange,
    onSubmit,
  } = props;

  return (
    <div className={ "register" + (hidden ? " hidden" : "") }>
      <img
        className="logo"
        src={logo}
        alt="logo"
      />
      <TextField
        id="account"
        group="register"
        title={ t('account') }
        placeholder={ t('accountPlaceholder') }
        onChange={ onChange }
      />
      <TextField
        id="password"
        type="password"
        group="register"
        title={ t('password') }
        placeholder={ t('passwordPlaceholder') }
        onChange={ onChange }
      />
      <TextField
        id="farmName"
        group="register"
        title={ `${t('farmName')} ${t('optional')}` }
        placeholder={ t('farmNamePlaceholder') }
        onChange={ onChange }
      />
      <TextField
        id="address"
        group="register"
        title={ `${t('address')} ${t('optional')}` }
        placeholder={ t('addressPlaceholder') }
        onChange={ onChange }
      />
      <SubmitButton onSubmit={ onSubmit }>
        { t('register') }
      </SubmitButton>
      <div className="link-wrapper">
        <Link to="/login">
          { t('alreadyHaveAccount') }
        </Link>
      </div>
      <div className="link-wrapper">
        <Link to="/forgot-password">
          { t('forgotPassword') }
        </Link>
      </div>
    </div>
  )
}

export default withTranslation('accountTranslation')(RegisterSubview);
