import React, {
  useCallback,
  useState,
} from "react";
import { Affix } from 'antd'
import './StickyWrap.scss'

const StickyWrap = (props) => {
  const {
    children,
    options,
  } = props
  const [isSticky, setIsSticky] = useState(false)
  const onChange = useCallback((affix) => {
    setIsSticky(affix)
    if(typeof options.onChange === 'function') {
      options.onChange(affix)
    }
  }, [options])

  return (
    <Affix
      className={`sticky-wrap ${isSticky ? 'sticky': ''}`.trim()}
      {...options}
      onChange={onChange}
    >
      { children }
    </Affix>
  )
}

export default StickyWrap