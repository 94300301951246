import {
  createStore,
  combineReducers,
  applyMiddleware,
  compose,
} from 'redux';
import {
  connectRouter,
  routerMiddleware ,
} from 'connected-react-router'
import logger from 'redux-logger'
import thunkMiddleware from 'redux-thunk';
import history from '@/historyRef'
import * as reducers from './reducers';

const reducer = combineReducers({
  ...reducers,
  router: connectRouter(history)
});
let middleware = [ routerMiddleware(history), thunkMiddleware ];
if(process.env.NODE_ENV !== 'production') {
  middleware = [ ...middleware, logger ];
}
const store = createStore(
  reducer,
  compose(
    applyMiddleware(...middleware)
  )
);

export default store;
