import {
  useState,
  useCallback
} from 'react';
import { Input } from 'antd';
import { useTranslation } from 'react-i18next';
import Dropdown from './Dropdown';
import DownloadButton from './DownloadButton';
import './Toolbox.scss';
const { Search } = Input

const Toolbox = (props) => {
  const {
    target,
    tools,
  } = props
  const { t } = useTranslation()
  const [activeIndex, setActiveIndex] = useState()

  const handleItemClick = useCallback((index) => {
    return e => {
      setActiveIndex(pre => pre === index ? undefined : index)
    }
  }, [activeIndex])

  return (
    <div className="toolbox">
      {
        tools.map((tool, index) => {
          const {
            type,
            data,
            count,
            animal_name,
            onSearch,
          } = tool
          let template
          switch(type) {
            case "sort":
            case "x-axis":
            case "y-axis":
              template = (
                <Dropdown
                  key={ type }
                  type={type}
                  isActive={ activeIndex === index }
                  data={ data }
                  target={target}
                  onSelect={ handleItemClick(index) } />
              )
              break
            case "download":
              template = (
                <DownloadButton
                  key={type}
                  animal_name={animal_name}  
                />
              )
              break
            case "count":
              template = (
                <div
                  key={ type }
                  className="data-count"
                >
                  { t('total', { total: count}) }
                </div>
              )
              break
            case 'search':
              template = (
                <div
                  key={type}
                  className='search'
                >
                  <Search
                    placeholder={t('animal_name_placeholder')}
                    allowClear
                    onSearch={onSearch}
                  />
                </div>
              )
              break
            default:
              break
          }
          return template
        })
      }
      <div className="clear" />
    </div>
  )
}

export default Toolbox
