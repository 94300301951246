import { useAsyncState } from "@hooks"

const useToggle = (defaultValue) => {
  const [value, setValue] = useAsyncState(defaultValue)

  function toggleValue(value) {
    setValue(currentValue =>
      typeof value === "boolean" ? value : !currentValue
    )
  }

  return [value, toggleValue]
}

export default useToggle
