import React, {
  useCallback,
  useRef,
  useMemo,
} from 'react';
import {
  useHistory,
  useLocation,
} from 'react-router-dom'
import moment from 'moment';
import 'moment-timezone';
import { withTranslation } from 'react-i18next'
import {
  getDivideValue,
  round,
} from '@utils/Numbers'
import { checkProAuth } from '@utils/Auth'
import { useToggle } from '@hooks';
import { TooltipWrap } from '@components'
import './DataRow.scss';

const DataRow = (props) => {
  const {
    t,
    data = {},
    firstRowKeys = [],
    secondRowKeys = [],
    showDetail = false,
    showInfo = false,
    onVideoBtnClick = null,
  } = props
  const {
    age,
    timestamp,
    animal_name,
    animal_id,
    analyses,
  } = data
  const history = useHistory()
  const location = useLocation()
  const search_params = new URLSearchParams(location.search)
  const animalId = search_params.get('animal_id')
  const hasProAuth = useRef(checkProAuth())

  const isEmptyAnalyses = useMemo(() => {
    const { analyses } = data
    return !analyses || !analyses.length
  }, [data])

  const [isExpand, toggleExpand] = useToggle(false)
  const gotoAnimal = useCallback(() => {
    history.push(`/experiments?animal_id=${animal_id}`);
  }, [animal_id])
  const gotoAnimalInfo = useCallback(() => {
    history.push(`/animals/${animal_id}`);
  }, [animal_id])
  const toggleSecondRow = useCallback(() => {
    toggleExpand(!isExpand)
  }, [toggleExpand, isExpand])
  const calculateValue = useCallback((key) => {
    let value = data[key]
    if(value === null) return 'N/A'
    const divide = getDivideValue(key)
    value = value / divide
    value = round(value)
    if(key === 'dilution_ratio') {
      value = `1:${value}`
    }
    return value
  }, [data])

  const handleVideoBtnClick = useCallback((e) => {
    if(isEmptyAnalyses) return
    if(typeof onVideoBtnClick === 'function') {
      onVideoBtnClick(e.target, {
        animal_name,
        date: moment(timestamp).tz(moment.tz.guess()).format(format),
        analyses,
      })
    }

  }, [onVideoBtnClick, isEmptyAnalyses, data])

  const unitMap = {
    concentration_avg: 'concentrationUnit',
    experiments_count: 'countUnit',
    abnormality_avg: '%',
    motility_avg: '%',
    measured_concentration: 'concentrationUnit',
    dilution_ratio: ' ',
    raw_concentration: 'concentrationUnit',
    motile: '%',
    progressive: '%',
    abnormality: '%',
    total_semen_volume: 'ml',
    total_sperm: 'spermCountUnit',
    total_effective_sperm: 'spermCountUnit',
    dose_volume: 'ml',
    dose_effective_sperm: 'spermCountUnit',
    number_of_doses: 'doseUnit',
    extender_per_dose: 'ml',
  }

  // Process time
  const format = "YYYY-MM-DD HH:mm:ss";
  const date = moment(timestamp).tz(moment.tz.guess()).format(format);

  return (
    <div className="data-row">
      <div className="first-layer content-wrap">
        <div className="info-block">
          <div className="flex-wrap">
            <div className="name">
              <TooltipWrap>
                { animal_name }
              </TooltipWrap>
            </div>
            {
              showInfo && hasProAuth.current &&
              <React.Fragment>
                <span className="age">
                  {
                    t('age_withColon', {
                      age: [
                        Math.floor(age/12),
                        age%12,
                      ]
                    })
                  }
                </span>
                <span
                  className="edit"
                  onClick={ gotoAnimalInfo }
                >
                  { t('edit') }
                  <i className="aidmics-edit" />
                </span>
              </React.Fragment>
            }
          </div>
          {
            showDetail &&
            <div className="detail-block tablet-hide">
              {
                !animalId &&
                <button className="detail"
                  onClick={ gotoAnimal }
                >
                  { t('view_animal') }
                </button>
              }
              {
                !showInfo &&
                <button className={`detail ${isEmptyAnalyses ? 'disabled' : ''}`.trim()}
                  onClick={ handleVideoBtnClick }
                >
                  { t('analyses') }
                </button>
              }
            </div>
          }
        </div>
        <div className="data-block"
          onClick={ secondRowKeys.length ? toggleSecondRow : null }
        >
          <span className="date tablet-hide">
            { t('lastMeasureTime', { date, pre_line: '' }) }
          </span>
          <div className="columns">
            {
              !!firstRowKeys.length &&
              firstRowKeys.map(key => {
                const value = calculateValue(key)
                const unit = value !== 'N/A' ? t(unitMap[key]) : ''

                return (
                  <div className="column"
                    key={key}
                  >
                    <div className="title">
                      { t(key) }
                    </div>
                    <div className="content">
                      { `${value} ${unit}`.trim() }
                    </div>
                  </div>
                )
              })
            }
          </div>
          <span className="date md-hide">
            { t('lastMeasureTime', { date, pre_line: '' }) }
          </span>
        </div>
        {
          showDetail &&
          <div className="detail-block md-hide">
            {
              !animalId &&
              <button className="detail"
                onClick={ gotoAnimal }
              >
                { t('view_animal') }
              </button>
            }
            {
              !showInfo &&
              <button className={`detail ${isEmptyAnalyses ? 'disabled' : ''}`.trim()}
                onClick={ handleVideoBtnClick }
              >
                { t('analyses') }
              </button>
            }
          </div>
        }
      </div>
      {
        !!secondRowKeys.length &&
        <div className={ "second-layer content-wrap" + (isExpand ? "" : " hidden") }>
          <div className="columns">
            {
              secondRowKeys.map(key => {
                const value = calculateValue(key)
                const unit = value !== 'N/A' ? t(unitMap[key]) : ''
                
                return (
                  <div className="column"
                    key={key}
                  >
                    <div className="title">
                      { t(key) }
                    </div>
                    <div className="content">
                    { `${value} ${unit}`.trim() }
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
      }
    </div>
  );
}

export default withTranslation('commonPhrases')(DataRow);
