const errorTypes = {
  // Common
  serverDown        : 100,   // Server 爛了
  noConnection      : 101,   // 沒有網路連線（client 端確認）
  invalidToken      : 102,   // Token 不合法
  // Account
  emptyInfo         : 200,   // 傳回來的東西有漏
  invalidAccount    : 201,   // 帳號不存在
  invalidPassword   : 202,   // 密碼錯誤
  userSkipped       : 203,   // User 按下 skip（client 端確認）/*** app only ***/
  duplicatedAccount : 204,   // Email 重複
  accountFormat     : 205,   // 帳號格式錯誤
  passwordFormat    : 206,   // 密碼格式錯誤
  samePassword      : 207,   // 新舊密碼相同
  invalidKey        : 208,   // 忘記密碼的 key 不合法（過期、重複或就是錯的）
  // Upload
  cannotDump        : 300,   // 無法 dump db /*** app only ***/
  // Data
  notFound          : 404,   // 找不到資料
}

const getErrorMessage = (errorType) => {
  const errorMessageMapping = {
    100: "serverDown",
    101: "noConnection",
    102: "invalidToken",
    103: "invalidLicense",
    200: "emptyInfo",
    201: "invalidAccount",
    202: "invalidPassword",
    204: "duplicatedAccount",
    205: "accountFormat",
    206: "passwordFormat",
    207: "samePassword",
    208: "invalidKey",
    404: "notFound",
  }
  const errorMessage = errorMessageMapping[errorType];
  return errorMessage
}
export {
  errorTypes,
  getErrorMessage
}