import React, {
  useRef,
  useCallback,
  useState,
  useEffect,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useCookies } from 'react-cookie';
import { Link } from 'react-router-dom'
import { hideMenu } from '@redux/actions';
import NavItem from './NavItem'
import NavTitle from './NavTitle'
import HamburgerButton from './HamburgerButton'
import {
  LanguageSwitch,
  SpeciesLabel,
} from '@components'
import { checkProAuth } from '@utils/Auth'
import { useMatchMedia } from '@hooks'
import logo from '@/assets/images/logo.svg'
import './Navbar.scss';

const Navbar = (props) => {
  const { t } = useTranslation('navItemTitles')
  const dispatch = useDispatch()
  const hasProAuth = checkProAuth()
  const [cookie] = useCookies()
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024)
  useMatchMedia('(max-width: 1024px)', setIsMobile)
  const mobileMenuShown = useSelector(state => state.navbar.mobileMenuShown)

  useEffect(() => {
    if(isMobile && mobileMenuShown) {
      document.body.classList.add('fixed-body')
    } else {
      document.body.classList.remove('fixed-body')
    }
    
    return () => {
      document.body.classList.remove('fixed-body')
    }
  }, [isMobile, mobileMenuShown])

  const containerRef = useRef(null)
  const handleNavClick = useCallback(() => {
    containerRef.current.scrollTo(0, 0)
    dispatch(hideMenu())
  }, [])

  const navData = [
    {
      url: '/animals',
      type: 'page',
      name: 'animals',
      float: 'right',
      needProAuth: false,
    },
    {
      url: '/experiments',
      type: 'page',
      name: 'experiments',
      float: 'right',
      needProAuth: false,
    },
    {
      url: '/trends',
      type: 'page',
      name: 'trends',
      float: 'right',
      needProAuth: true,
    },
    {
      url: '/semenOrder',
      type: 'page',
      name: 'semenOrder',
      float: 'right',
      needProAuth: true,
    },
    {
      url: '/account',
      type: 'scroll',
      name: 'account',
      float: 'right',
      needProAuth: false,
    },
  ]

  return (
    <div
      ref={containerRef}
      className={`navbar-container${mobileMenuShown ? ' mobile-menu-show' : ''}`}
    >
      <div className="navbar-content">
        <div className="navbar-content-item navbar-topper mobile-space-between">
          <NavTitle />
          {
            !cookie.isWebview &&
            <HamburgerButton />
          }
        </div>
        <div className="navbar-content-item mobile-only">
          <img
            className="mobile-menu-logo"
            src={logo}
            alt="logo"
          />
        </div>
        <ul className="navbar-content-item mobile-flex-column">
          {
            navData.map(nd => {
              if(nd.needProAuth && !hasProAuth) return null
              return (
                <NavItem
                  data={ nd }
                  onNavClick={handleNavClick}
                  key={ nd.name }
                />
              )
            })
          }
        </ul>
        <span className="separated-bar mobile-only" />
        <div className="navbar-content-item mobile-only">
          <Link
            className='species-nav'
            to="/species"
            onClick={handleNavClick}
          >
            { t('species') }
            <span className="dash" />
            <SpeciesLabel />
          </Link>
        </div>
        <span className="separated-bar mobile-only" />
        <div className="navbar-content-item mobile-only">
          <LanguageSwitch />
        </div>
      </div>
    </div>
  )
}

export default Navbar;
