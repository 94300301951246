import React, { Component } from 'react';
import { withTranslation } from 'react-i18next'
import './TextField.scss';

class TextField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shouldHidePassword: true,
      inEditMode: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.togglePassword = this.togglePassword.bind(this);
    this.editDidBegin = this.editDidBegin.bind(this);
    this.editDidFinish = this.editDidFinish.bind(this);
  }
  handleChange(event) {
    const {
      id,
      onChange,
      group,
    } = this.props

    if(onChange) {
      const newValue = event.target.value;
      onChange(group, id, newValue);
    }
  }
  togglePassword() {
    const {
      shouldHidePassword,
    } = this.state

    this.setState({
      ...this.state,
      shouldHidePassword: !shouldHidePassword,
    });
  }
  editDidBegin() {
    const {
      id,
      onEdit,
    } = this.props
    // Customize for edit password
    if(id === "editPassword") {
      onEdit();
    } else {
      this.setState({
        ...this.state,
        inEditMode: true,
      });
    }
  }
  editDidFinish() {
    const {
      id,
      group,
      value,
      editDidFinish,
    } = this.props

    const newValue = value;
    editDidFinish(group, id, newValue);

    this.setState({
      ...this.state,
      inEditMode: false,
    });
  }
  render() {
    const {
      t,
      // id,
      className,
      title,
      editable,
      value,
      type,
      placeholder,
    } = this.props;
    const {
      inEditMode,
      shouldHidePassword,
    } = this.state
    // Customize for password
    let currentType = "text";
    let isPassword = type === "password"
    if(isPassword && shouldHidePassword) {
      currentType = "password";
    }
    return (
      <div className={ ["text-field", className].join(" ") }>
        <div className="title">
          { title }
        </div>
        <div className="input-wrapper">
          <input
            type={ currentType }
            value={ value }
            placeholder={ placeholder || "" }
            disabled={ editable && !inEditMode }
            onChange={ this.handleChange }
            onKeyPress={ this.props.onKeyPress }
          />
          <div
            className={ "show-password" + (isPassword ? "" : " hidden") }
            onClick={ this.togglePassword }
          >
            <i className={ `aidmics-${shouldHidePassword ? 'eye' : 'disabled-eye'}` } />
            <span>
              { shouldHidePassword ? t('show') : t('hide') }
            </span>
          </div>
          <div className={ "edit-button"
              + (editable ? "" : " hidden")
              + (inEditMode ? " in-edit-mode" : "")}
            onClick={ this.editDidBegin }
          >
            <i className="aidmics-edit" />
            <span>
              { t('edit') }
            </span>
          </div>
          <div
            className={ "ok-button" + ( editable && inEditMode ? "" : " hidden" ) }
            onClick={ this.editDidFinish }
          >
            { t('ok') }
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation('accountTranslation')(TextField);

