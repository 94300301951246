import React, { useCallback } from 'react';
import {
  Link,
  useLocation,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next'
import './NavItem.scss';

const NavItem = (props) => {
  const {
    data,
    onNavClick,
  } = props
  const { t } = useTranslation('navItemTitles')
  const location = useLocation()
  const scrollToDestination = useCallback(e => {
    onNavClick()
  }, [])

  const {
    url,
    name,
    type,
  } = data

  const shouldNavigate = location.pathname !== url

  let link;
  switch (type) {
    case "scroll":
    case "page":
      link = shouldNavigate ?
        (
          <Link
            to={ url }
            onClick={ scrollToDestination }
          >
            { t(name) }
          </Link>
        ) :
        (
          <span onClick={ scrollToDestination }>
            { t(name) }
          </span>
        )
      break;
    default:
      link = ( <noscript /> );
      break
  }

  return (
    <li className="nav-item" >
      { link }
    </li>
  )
}

export default NavItem
