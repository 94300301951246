import store from '@redux/store';
import { Buffer } from 'buffer'
import { Cookies } from 'react-cookie'
import { changeSpecies } from '@redux/actions';
const cookie = new Cookies()

/******************/
/***** Public *****/
/******************/


/***** Token *****/

const getToken = () => {
  return cookie.get('token')
}

const forgetSpecies = () => {
  store.dispatch(changeSpecies("WW"));
}

const isBase64 = (str) => {
  const base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/
  return base64regex.test(str)
}
const parseJWT = () => {
  const token = getToken()
  if(!token) return null
  const base64Payload = token.split('.')[1]
  if(!isBase64(base64Payload)) {
    return null
  }
  const decodeToken = JSON.parse(Buffer.from(base64Payload, 'base64').toString())
  return decodeToken
}

const checkProAuth = () => {
  const jwt = parseJWT()
  if(!jwt) return false
  const {
    report
  } = jwt
  return report
}


export {
  getToken,
  forgetSpecies,
  parseJWT,
  checkProAuth,
}

