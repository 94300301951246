import {
  useRef,
  useState,
  useCallback,
  useEffect,
} from 'react'

const usePage = (
  defaultPage = 1,
  defaultPageSize = 20,
  pageOptions = [20, 50]
) => {
  const pageSizeOptions = useRef(pageOptions)
  const [currentPage, setCurrentPage] = useState(defaultPage)
  const [pageSize, setPageSize] = useState(defaultPageSize)
  const handleOnChange = useCallback((page, size) => {
    setCurrentPage(page)
    setPageSize(size)
  }, [])
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [currentPage, pageSize])
  return {
    pageSizeOptions,
    currentPage,
    pageSize,
    handleOnChange,
  }
}

export default usePage