import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  connect,
  useDispatch,
} from 'react-redux';
import {
  useParams,
  useHistory,
  useLocation,
} from 'react-router-dom'
import { recordFPEmail } from '@redux/actions';
import {
  getErrorMessage,
  errorTypes,
} from '@utils/Errors'
import { isEmpty } from '@utils/Validate'
import { delay } from '@utils'
import {
  login,
  registerAccount,
  forgetPassword,
  resetPassword,
} from '@api'
// components
import {
  WarningBar,
  Loading
} from '@components';
import {
  useToggle,
  useAuthCookies,
} from '@hooks'
import LoginSubview from './LoginSubview'
import RegisterSubview from './RegisterSubview'
import ForgotPasswordSubview from './ForgotPasswordSubview'
import ResetPasswordSubview from './ResetPasswordSubview'
import InstructionSubview from './InstructionSubview'
import ResetSuccessSubview from './ResetSuccessSubview'
import './Login.scss';

const Login = (props) => {
  const {
    mode,
  } = props
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const params = useParams()
  const { token, setToken } = useAuthCookies()
  const [shouldShowError, setShowError] = useToggle(false)
  const [shouldShowLoading, setShowLoading] = useToggle(false)
  const [errorMessage, setErrorMessage] = useState('')
  const loginForm = useRef({})
  const registerForm = useRef({})
  const forgotPasswordForm = useRef({})
  const resetPasswordForm = useRef({})
  
  useEffect(() => {
    if(token && !isEmpty(token)) {
      history.replace({
        pathname: '/experiments',
        state: {
          from: 'login',
        }
      })
    }
  }, [mode])

  const redirectRoute = useCallback(() => {
    let path = '/experiments'
    const {
      state
    } = location

    if(state && state.from) {
      path = state.from
    }
    history.push(path)
  }, [])

  const showError = useCallback((errorType) => {
    const errorMessage = getErrorMessage(errorType)
    setShowError(true)
    setErrorMessage(errorMessage)
  }, [setShowError, setErrorMessage])

  const hideError = useCallback(() => {
    setShowError(false)
  }, [])

  const handleChange = useCallback((mode, id, value) => {
    const updatedVal = {
      [id]: value
    }
    switch(mode) {
      case 'login':
        loginForm.current = Object.assign(loginForm.current, updatedVal)
        break
      case 'register':
        registerForm.current = Object.assign(registerForm.current, updatedVal)
        break
      case 'forgotPassword':
        forgotPasswordForm.current = Object.assign(forgotPasswordForm.current, updatedVal)
        break
      case 'resetPassword':
        resetPasswordForm.current = Object.assign(resetPasswordForm.current, updatedVal)
        break
      default:
        break
    }
  }, [])

  const handleLogin = useCallback(async () => {
    setShowLoading(true)
    try {
      const {
        account,
        password,
      } = loginForm.current
      const res = await login({
        account,
        password,
      })
      setToken(res.token)
      redirectRoute()
    } catch(err) {
      console.error(err)
      showError(err.errorType)
    } finally {
      setShowLoading(false)
    }
  }, [])

  const handleRegister = useCallback(async () => {
    setShowLoading(true)
    try {
      const {
        account = '',
        password = '',
        farmName = '',
        address = '',
      } = registerForm.current
      const res = await registerAccount({
        account,
        password,
        farmName,
        address,
      })
      setToken(res.token)
      redirectRoute()
    } catch (err) {
      showError(err.errorType)
    } finally {
      setShowLoading(false)
    }
  }, [])

  const handleForgotPassword = useCallback(async () => {
    setShowLoading(true)
    try {
      const {
        account,
      } = forgotPasswordForm.current
      await forgetPassword({
        account,
      })
      dispatch(recordFPEmail(account));
      history.push('/forgot-password-sent')
    } catch (err) {
      showError(err.errorType)
    } finally {
      setShowLoading(false)
    }
  }, [])

  const handleResetPassword = useCallback(async () => {
    setShowLoading(true)
    try {
      const { key } = params
      const {
        newPassword,
      } = resetPasswordForm.current
      await resetPassword({
        key,
        newPassword,
      })
      history.push('/reset-password-success')
    } catch (err) {
      showError(err.errorType)
      if(err.errorType === errorTypes.invalidKey) {
        await delay(2000)
        history.push('/forgot-password')
      }
    } finally {
      setShowLoading(false)
    }
  }, [])

  let View
  switch (mode) {
    case 'login':
      View = (
        <LoginSubview
          onChange={ handleChange }
          onSubmit={ handleLogin }
        />
      )
      break;
    case 'register':
      View = (
        <RegisterSubview
          onChange={ handleChange }
          onSubmit={ handleRegister }
        />
      )
      break;
    case 'forgotPassword':
      View = (
        <ForgotPasswordSubview
          onChange={ handleChange }
          onSubmit={ handleForgotPassword }
        />
      )
      break;
    case 'resetPassword':
      View = (
        <ResetPasswordSubview
          onChange={ handleChange }
          onSubmit={ handleResetPassword }
        />
      )
      break;
    case 'instruction':
      View = (
        <InstructionSubview />
      )
      break;
    case 'resetSuccess':
      View = (
        <ResetSuccessSubview />
      )
      break;
    default:
      break;
  }
  return (
    <div className="login-wrapper wrapper">
      { View }
      <WarningBar
        text={ errorMessage }
        hidden={ !shouldShowError }
        onClick={ hideError }
      />
      {
        shouldShowLoading &&
        <Loading />
      }
    </div>
  );
}

export default connect()(Login);

