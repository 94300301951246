import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom';
import logo from '@/assets/images/logo.svg'
import './InstructionSubview.scss';

const InstructionSubview = (props) => {
  const {
    t,
    hidden,
    email,
  } = props;
  return (
    <div className={ "instruction" + (hidden ? " hidden" : "") }>
      <img
        className="logo"
        src={logo}
        alt="logo"
      />
      <div className="description">
        { t('forgotPasswordInstructions.0') }
      </div>
      <div className="description email">
        { email }
      </div>
      <div className="description">
        { t('forgotPasswordInstructions.1') }
      </div>
      <div className="link-wrapper">
        <Link to="/login">
          { t('backToLogin') }
        </Link>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    email: state.account.forgotPassEmail,
  }
}
const ConnectedInstructionSubview = connect(mapStateToProps)(InstructionSubview);
export default withTranslation('accountTranslation')(ConnectedInstructionSubview);
