import { useCallback } from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useSelector,
  useDispatch,
} from 'react-redux';
import {
  sort,
  changeX,
  changeY,
} from '@redux/actions';
import './Dropdown.scss';

const Dropdown = (props) => {
  const {
    type,
    data,
    target,
    isActive,
    onSelect,
  } = props
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const sortBy = useSelector(state => state.data.sortBy)
  const xAxis = useSelector(state => state.chart.xAxis)
  const yAxis = useSelector(state => state.chart.yAxis)
  const activeMaps = useMemo(() => {
    return {
      'sort': {
        type: sortBy,
        icon: 'md-funnel',
        title: t('sort'),
        action: sort,
      },
      'x-axis': {
        type: xAxis,
        icon: 'arrow-down-b',
        title: `${t('xAxis')}: ${t(xAxis)}`,
        action: changeX,
      },
      'y-axis': {
        type: yAxis,
        icon: 'arrow-down-b',
        title: `${t('yAxis')}: ${t(yAxis)}`,
        action: changeY,
      },
    }
  }, [t, sortBy, xAxis, yAxis])
  const activeType = useMemo(() => {
    return activeMaps[type]
  }, [type, activeMaps])

  const toggleMenu = useCallback(() => {
    if(typeof onSelect !== 'function') return
    onSelect()
  }, [onSelect])

  const onItemSelect = useCallback(key => {
    return e => {
      if(type === 'sort') {
        dispatch(activeType.action(target, key))
      } else {
        dispatch(activeType.action(key))
      }
      toggleMenu()
    }
  }, [type, target, activeType, toggleMenu])

  return (
    <div className="dropdown">
      <div
        className="button"
        onClick={toggleMenu}
      >
        <i className={`aidmics-${activeType.icon}`} />
        { activeType.title }
      </div>
      <div className={`menu ${isActive ? '' : 'hidden'}`}>
        <ul>
          {
            data.map(el => {
              return (
                <li
                  key={el}
                  className={el === activeType.type ? 'selected' : ''}
                  onClick={onItemSelect(el)}
                >
                  { t(el) }
                </li>
              )
            })
          }
        </ul>
      </div>
    </div>
  )
}

export default Dropdown
