import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { Pagination } from 'antd'
import {
  SwitchTransition,
  CSSTransition,
} from 'react-transition-group'
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  changeHeaderTitle,
  fetchTrendReportList,
  setAnchor,
  setPageNumber,
  setPageSize,
} from '@redux/actions';
import {
  getTimeFormat,
  getChartData,
  getDivideValue,
} from '@utils/Numbers'
import {
  Loading,
  LineChart,
  TooltipWrap,
  StickyWrap,
  SimpleFooter,
} from '@components';
import { useToggle } from '@hooks';
import scrollTo from '@utils/Scroll';
import './TrendReport.scss';

const TrendReport = (props) => {
  const { t } = useTranslation('commonPhrases')
  const [isLoading, toggleLoading] = useToggle(true)
  const [isInnerLoading, toggleInnerLoading] = useToggle(false)
  const dispatch = useDispatch()
  const history = useHistory()
  const trendList = useSelector(state => state.trendReport.trendReportList)
  const length = trendList?.length
  const thresholds = useSelector(state => state.trendReport.thresholds)
  const anchor = useSelector(state => state.trendReport.anchor)
  const pageNumber = useSelector(state => state.trendReport.pageNumber)
  const pageSize = useSelector(state => state.trendReport.pageSize)
  const pageSizeOptions = useRef([
    20,
    50,
  ])
  const handlePageChange = useCallback((page, size) => {
    dispatch(setPageNumber(page))
    dispatch(setPageSize(size))
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pageNumber, pageSize])

  const [isSticky, setIsSticky] = useState(true)
  const stickyOption = useRef({
    offsetBottom: 0,
    onChange: (affix) => {
      setIsSticky(affix)
    }
  })

  const getTrendReport = useCallback(async () => {
    try {
      await dispatch(fetchTrendReportList({
        order_by: 'exp_time'
      }))
    } catch(err) {
      // do something
    } finally {
      toggleLoading(false)
    }
  }, [])

  useEffect(() => {
    dispatch(changeHeaderTitle('trends'))
    if(anchor) {
      toggleLoading(false)
    } else {
      getTrendReport()
    }
  }, [])

  useEffect(() => {
    if(!isLoading && anchor) {
      const el = document.querySelector(`#anchor-${anchor}`)
      if(el) {
        const top = el.offsetTop - 116
        scrollTo(top, ()=> {}, 200)
      }
    }
  }, [isLoading])

  const goToDetail = useCallback((id) => {
    return e => {
      dispatch(setAnchor(id))
      history.push(`/trends/${id}`)
    }
  }, [])

  const handleRefresh = useCallback(async () => {
    scrollTo(0, () => {}, 200)
    toggleInnerLoading(true)
    try {
      dispatch(setPageNumber(1))
      await getTrendReport()
    } catch(err) {
      console.error(err)
    } finally {
      toggleInnerLoading(false)
    }
  }, [])

  if(isLoading) {
    return (
      <Loading />
    )
  }
  const start = (pageNumber - 1) * pageSize
  const end = pageNumber * pageSize

  const xAxis = 'day'
  const yAxis = 'total_effective_sperm'
  const timeFormat = getTimeFormat(xAxis)
  const startDate = moment().tz(moment.tz.guess()).subtract(180, 'days').format(timeFormat)
  const divide = getDivideValue(yAxis)
  const threshold = thresholds[yAxis] ? thresholds[yAxis] / divide : null

  return (
    <div className="trend-report wrapper">
      <div className="mark-area sticky">
        <p>
          { t('total', { total: trendList.length}) }
        </p>
        <div className="refresh">
          <button onClick={handleRefresh}>
            <i className="aidmics-refresh" />
            { t('refresh') }
          </button>
        </div>
      </div>
      <SwitchTransition component={null}>
        <CSSTransition
          key={`page-${pageNumber}}`}
          classNames="route"
          timeout={{
            enter: 100,
            exit: 50,
          }}
        >
          <ul className="trend-list">
            <li className="trend-list-item sticky content-wrap">
              <div className="info-block">
                { t('animals') }
              </div>
              <div className="chart-block">
                { `${t('motileSperm')} | ${t('threshold')} (${t('spermCountUnit')})` }
              </div>
              <div className="detail-block">
                { t('detail') }
              </div>
            </li>
            {
              trendList.slice(start, end).map(list => {
                const {
                  id,
                  name,
                  age,
                  experiments,
                  status,
                } = list

                const averages = getChartData(experiments, xAxis, yAxis, startDate)
                const chartData = averages.series || []
                const dataLength = chartData.length

                let last_measurement
                let yMax = 10
                if(dataLength) {
                  yMax = Math.max(...chartData.map(d => d.y), threshold, yMax)
                  const xMax = Math.max(...chartData.map(d => d.x))
                  last_measurement = moment(startDate).add(xMax, 'days').format(timeFormat)
                }

                const xDomain = [0, 180]
                const yDomain = [0, yMax]

                const allGood = status.all === 'normal'
                
                // const color = allGood ? '#001B59' : '#E33030'
                
                return (
                  <li
                    className="trend-list-item content-wrap"
                    id={`anchor-${id}`}
                    key={ id }
                  >
                    <div className="info-block">
                      <div className="flex-column">
                        <div className="name">
                          <TooltipWrap
                            tooltipOption={{
                              placement: "topRight"
                            }}
                          >
                            { name }
                          </TooltipWrap>
                        </div>
                        <p className="age">
                          {
                            t('age_withColon', {
                              age: [
                                Math.floor(age/12),
                                age%12,
                              ]
                            })
                          }
                        </p>
                        <p>
                          { 
                            t('lastMeasureTime', {
                              date: last_measurement,
                              pre_line: '\n',
                            }) 
                          }
                        </p>
                      </div>
                    </div>
                    <div className="chart-block">
                      <LineChart
                        id={id}
                        data={ averages }
                        xAxis={ xAxis }
                        yAxis={ yAxis }
                        xDomain={ xDomain }
                        yDomain={ yDomain }
                        showYAxis={ false }
                        showGridLine={ false }
                        threshold={ threshold }
                        // color={ color }
                        chartType="line"
                        timeFormat="MM/DD"
                        tickPadding={60}
                        height={ 180 }
                      />
                    </div>
                    <div className="detail-block">
                      <div className="flex-column">
                        <button
                          className={allGood ? 'btn-good' : 'btn-warn'}
                          onClick={ goToDetail(id) }
                        >
                          { t('detail') }
                        </button>
                        <div className={`status-area md-hide ${!allGood ? 'text-red': ''}`.trim()}>
                          {
                            allGood ?
                            <p className="message">
                              <i className="aidmics-normal"></i>
                              <span>
                                { t('status_allGood') }
                              </span>
                            </p> :
                            Object.keys(status).map(key => {
                              const state = status[key]
                              if(state === null || state === 'normal' || key === 'all') return null
                              return (
                                <p
                                  className="message"
                                  key={key}
                                >
                                  <i className="aidmics-warning"></i>
                                  <span>
                                    { t(key) }
                                  </span>
                                </p>
                              )
                            })
                          }
                        </div>
                        <div className={`status-area tablet-hide ${!allGood ? 'text-red': ''}`.trim()}>
                          {
                            allGood ?
                            <p className="message">
                              <i className="aidmics-normal"></i>
                              <span>
                                { t('status_allGood') }
                              </span>
                            </p> :
                            <p className="message">
                              <i className="aidmics-warning"></i>
                              <span>
                                { t('status_partGood') }
                              </span>
                            </p>
                          }
                        </div>
                      </div>
                    </div>
                  </li>
                )
              })
            }
          </ul>
        </CSSTransition>
      </SwitchTransition>
      {
        length > pageSizeOptions.current[0] &&
        <StickyWrap
          options={stickyOption.current}
        >
          <div className="page-wrap">
            <SimpleFooter isVisible={isSticky} />
            <Pagination
              showSizeChanger
              current={pageNumber}
              defaultCurrent={pageNumber}
              total={length}
              defaultPageSize={pageSize}
              pageSizeOptions={pageSizeOptions.current}
              onChange={handlePageChange}
            />
          </div>
        </StickyWrap>
      }
      {
        isInnerLoading &&
        <Loading />
      }
    </div>
  );
}

export default TrendReport;
