import React, { Component } from 'react';
import './SubmitButton.scss';

class SubmitButton extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }
  handleClick(event) {
    this.props.onSubmit();
  }
  render() {
    return (
      <div
      className={ ["submit-button", this.props.className].join(" ") }
      onClick={ this.handleClick } >
        { this.props.children }
      </div>
    );
  }
}

export default SubmitButton
