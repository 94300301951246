import {
  useCallback,
  useEffect,
} from 'react'

const useMatchMedia = (query, cb) => {
  const resize = useCallback((e) => {
    if(typeof cb === 'function') {
      cb(e.matches)
    }
  }, [])
  useEffect(() => {
    const mql = window.matchMedia(query)
    if('addEventListener' in mql) {
      mql.addEventListener('change', resize)
    } else if('addListener' in mql) {
      mql.addListener(resize)
    }

    return () => {
      if('removeEventListener' in mql) {
        mql.removeEventListener('change', resize)
      } else if('removeListener' in mql) {
        mql.removeListener(resize)
      }
    }
  }, [query])
}

export default useMatchMedia
