import {
  forwardRef,
  useCallback,
  useEffect,
} from 'react'
import { createPortal } from 'react-dom'
import './PositionedPopup.scss'

const PositionedPopup = forwardRef((props, ref) => {
  const {
    isVisible,
    classList = null,
    children,
    onClose = null,
  } = props

  useEffect(() => {
    if(isVisible) {
      document.body.classList.add('fixed-body')
    } else {
      document.body.classList.remove('fixed-body')
    }
    
    return () => {
      document.body.classList.remove('fixed-body')
    }
  }, [isVisible])

  const classes = [
    'positioned-popup',
    classList,
    isVisible && 'visible',
  ].filter(Boolean).join(' ')

  const handleOnClose = useCallback(() => {
    if(typeof onClose === 'function') {
      onClose()
    }
  }, [onClose])

  const template = (
    <div
      ref={ref}
      className={classes}
    >
      <div className="popup-content">
        <span
          className="popup-close"
          onClick={handleOnClose}
        />
        { isVisible && children }
      </div>
    </div>
  )

  return createPortal(
    template,
    document.body,
  )
})

export default PositionedPopup
