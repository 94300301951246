import {
  useCallback,
  useEffect,
  useRef,
  useMemo,
  useState,
} from 'react';
import { Pagination } from 'antd'
import {
  SwitchTransition,
  CSSTransition,
} from 'react-transition-group'
import { useTranslation } from 'react-i18next';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  fetchAnimalList,
  changeHeaderTitle,
} from '@redux/actions';
// components
import {
  DataRow,
  Toolbox,
  Loading,
  StickyWrap,
  SimpleFooter,
} from '@components';
import {
  usePage,
  useToggle,
} from '@hooks'
import './AnimalList.scss';

const AnimalList = (props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const data = useSelector(state => state.data.animalList)
  const [isLoading, toggleLoading] = useToggle(true)
  const {
    currentPage,
    pageSize,
    handleOnChange,
    pageSizeOptions,
  } = usePage(1, 50, [
    50,
    100,
    150
  ])

  const [isSticky, setIsSticky] = useState(true)
  const stickyOption = useRef({
    offsetBottom: 0,
    onChange: (affix) => {
      setIsSticky(affix)
    }
  })

  const fetchList = useCallback(async () => {
    try {
      await dispatch(fetchAnimalList())
    } catch(err) {
      console.log(err)
    } finally {
      toggleLoading(false)
    }
  }, [])

  useEffect(() => {
    dispatch(changeHeaderTitle('animals'))
    fetchList()
  }, [])

  // Search Tool
  const [searchText, setSearchText] = useState()
  const handleOnSearch = useCallback(text => {
    setSearchText(text)
  }, [])
  const filterData = useMemo(() => {
    if(!data?.length) return []
    if(!searchText) return data
    const reg = new RegExp(searchText, 'i')
    return data.filter(d => reg.test(d.animal_name))
  }, [data, searchText])
  
  // Data Rows
  const slicedData = useMemo(() => {
    const start = (currentPage - 1) * pageSize
    const end = currentPage * pageSize
    return filterData.slice(start, end)
  }, [filterData, currentPage, pageSize])
  const firstRowKeys = useRef([
    'concentration_avg',
    'motility_avg',
    'abnormality_avg',
    'experiments_count',
  ])

  if(isLoading) {
    return (
      <Loading />
    )
  }

  if(!data) {
    // prevent error when there's no data
    return (<noscript/>)
  }

  return (
    <div className="animal-list wrapper">
      <Toolbox
        target="animalList"
        tools={[
          {
            type: "sort",
            data: [
              'animal_name',
              'timestamp',
              'concentration_avg',
              'motility_avg',
              'abnormality_avg',
            ],
          },
          {
            type: "count",
            count: filterData ? filterData.length : 0,
          },
          {
            type: 'search',
            onSearch: handleOnSearch,
          },
        ]} 
      />
      <SwitchTransition component={null}>
        <CSSTransition
          key={`page-${currentPage}`}
          classNames="route"
          timeout={{
            enter: 100,
            exit: 50,
          }}
        >
          <div className="data-rows">
            {
              slicedData.length ?
              slicedData.map(d => {
                const {
                  animal_id,
                } = d
                
                return (
                  <DataRow
                    data={ d }
                    firstRowKeys={ firstRowKeys.current }
                    showDetail={ true }
                    showInfo={ true }
                    key={ animal_id }
                  />
                )
              }) :
              <div className="empty">
                { t('noAnimals') }
              </div>
            }
          </div>
        </CSSTransition>
      </SwitchTransition>
      {
        filterData.length > pageSizeOptions.current[0] &&
        <StickyWrap
          options={stickyOption.current}
        >
          <div className="page-wrap">
            <SimpleFooter isVisible={isSticky} />
            <Pagination
              showSizeChanger
              defaultCurrent={currentPage}
              total={filterData.length}
              defaultPageSize={pageSize}
              pageSizeOptions={pageSizeOptions.current}
              onChange={handleOnChange}
            />
          </div>
        </StickyWrap>
      }
    </div>
  )
}

export default AnimalList
