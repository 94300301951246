import React, { useEffect } from 'react';
import { createPortal } from 'react-dom'
import { withTranslation } from 'react-i18next'
import './Loading.scss';

const Loading = (props) => {
  const {
    t,
    className,
    appendToBody = true,
  } = props

  useEffect(() => {
    if(appendToBody) {
      document.body.classList.add('fixed-body')
    }
    return () => {
      if(appendToBody) {
        document.body.classList.remove('fixed-body')
      }
    }
  }, [])
  const classes = [
    'loading',
    className,
    !appendToBody && 'pos-absolute'
  ].filter(Boolean).join(' ')

  const template = (
    <div className={classes}>
      <i className="aidmics-load-c"></i>
      <p>
        { t('loadingData') }
      </p>
    </div>
  )
  if(appendToBody) {
    return createPortal(
      template,
      document.body
    )
  }

  return template
}

export default withTranslation('commonPhrases')(Loading);
