import React, { useCallback } from 'react';
import {
  useSelector,
  useDispatch,
} from 'react-redux';
import { useTranslation } from 'react-i18next'
import { toggleMenu } from '@redux/actions';
import Hamburger from './Hamburger';
import './HamburgerButton.scss';

const HamburgerButton = (props) => {
  const { t } =useTranslation('navItemTitles')
  const dispatch = useDispatch()
  const mobileMenuShown = useSelector(state => state.navbar.mobileMenuShown)
  const handleMenuToggle = useCallback(() => {
    dispatch(toggleMenu())
  }, [])

  return (
    <div
      className={ "hamburger-button" + (mobileMenuShown ? " menu-shown" : "") }
      onClick={ handleMenuToggle }
    >
      <Hamburger />
      <div className="hamburger-button-title">
        { t('menu') }
      </div>
    </div>
  )
}

export default HamburgerButton
