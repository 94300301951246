import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next'
import { getSpeciesColor } from '@utils/Species';
import './SpeciesLabel.scss';

const SpeciesLabel = (props) => {
  const {
    t,
    allSpecies,
    currentSpecies,
  } = props

  const history = useHistory()

  const goSelect = useCallback(() => {
    if(allSpecies.length < 2) return
    history.push({
      pathname: '/species',
      state: {
        species: allSpecies,
      }
    })
  }, [history, allSpecies])

  const species = currentSpecies === 'WW' ? 'SW' : currentSpecies

  const color = getSpeciesColor(currentSpecies);
  return (
    <div
      className="species-label"
      onClick={ goSelect }
    >
      <i
        className={`species-label-icon aidmics-${species}`}
        style={{
          color: color,
          borderColor: color
        }}
      />
      <div
        className="species-label-name"
        style={{ color: color }}
      >
        { t(species) }
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    currentSpecies: state.species.currentSpecies,
    allSpecies: state.species.allSpecies,
  }
}
const ConnectedSpeciesLabel = connect(mapStateToProps)(SpeciesLabel);
export default withTranslation('speciesTranslation')(ConnectedSpeciesLabel);
