export function delay(time = 0) {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve()
    }, time)
  })
}

export function shallowCompare(obj1, obj2) {
  const keyArr1 = Object.keys(obj1)
  const keyArr2 = Object.keys(obj2)
  return (keyArr1.length === keyArr2.length) &&
    keyArr1.every(key => {
      return obj2.hasOwnProperty(key) && (obj1[key] === obj2[key])
    })
}
