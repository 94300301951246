import React, {
  useEffect,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import {
  Switch,
  Route,
  Redirect,
  useLocation,
} from 'react-router-dom'
import {
  TransitionGroup,
  CSSTransition,
} from 'react-transition-group'
import { ConfigProvider } from 'antd'
import en_US from 'antd/lib/locale/en_US.js'
import zh_TW from 'antd/lib/locale/zh_TW.js'
import zh_CN from 'antd/lib/locale/zh_CN.js'
import { useDispatch } from 'react-redux'
import { resetTrendReport } from '@redux/actions';
import {
  Navbar,
  Footer,
  PrivateRoute,
} from '@components';
import {
  Download,
  ExperimentList,
  AnimalList,
  AnimalInfo,
  Login,
  Account,
  Species,
  TrendReport,
  Trend,
  SemenOrder,
  ErrorPage,
} from '@containers';

const App = (props) => {
  const { i18n } = useTranslation()
  const {
    language,
  } = i18n
  const dispatch = useDispatch()
  const location = useLocation()
  const {
    key,
    pathname,
  } = location

  const langMap = useRef({
    en: en_US,
    zh_tw: zh_TW,
    zh_cn: zh_CN,
  })

  const reg = /login|register|forgot-password|reset-password/
  const showFooter = reg.test(pathname)
  const [locale, setLocale] = useState(langMap.current[language])

  useEffect(() => {
    const lang = langMap.current[language]
    setLocale(lang)
  }, [language])

  useEffect(() => {
    const trendReg = /trends/
    if(!trendReg.test(pathname)) {
      dispatch(resetTrendReport())
    }
  }, [location])

  useEffect(() => {
    const setBootstrapVH = () => {
      let convertToPercentage = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${convertToPercentage}px`)
    }
    window.addEventListener('resize', setBootstrapVH)
    setBootstrapVH()
    return () => {
      window.removeEventListener('resize', setBootstrapVH)
    }
  }, [])

  return (
    <div>
      <ConfigProvider locale={locale}>
        {
          !showFooter &&
          <Navbar />
        }
        <TransitionGroup>
          <CSSTransition
            mode="out-in"
            classNames="route"
            timeout={{
              enter: 600,
              exit: 300,
            }}
            key={key}
          >
            <div className="animate-wrapper" key={key}>
              <Switch location={location}>
                <Route
                  exact
                  path="/"
                >
                  <Redirect to="/experiments" />
                </Route>
                <PrivateRoute
                  exact
                  path="/experiments"
                >
                  <ExperimentList />
                </PrivateRoute>
                <Route
                  exact
                  path="/measurements"
                >
                  <Redirect to="/experiments" />
                </Route>
                <PrivateRoute
                  exact
                  path="/animals"
                >
                  <AnimalList />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/animals/:animalID"
                  goBack={true}
                >
                  <AnimalInfo />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/trends"
                >
                  <TrendReport />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/trends/:animalID"
                  goBack={true}
                >
                  <Trend />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/semenOrder"
                >
                  <SemenOrder />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/account"
                >
                  <Account />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/species"
                >
                  <Species />
                </PrivateRoute>
                <Route
                  exact
                  path="/login"
                >
                  <Login mode="login" />
                </Route>
                <Route
                  exact
                  path="/register"
                >
                  <Login mode="register" />
                </Route>
                <Route
                  exact
                  path="/forgot-password"
                >
                  <Login mode="forgotPassword" />
                </Route>
                <Route
                  exact
                  path="/forgot-password-sent"
                >
                  <Login mode="instruction" />
                </Route>
                <Route
                  exact
                  path="/forgot-password/:key"
                >
                  <Login mode="resetPassword" />
                </Route>
                <Route
                  exact
                  path="/reset-password-success"
                >
                  <Login mode="resetSuccess" />
                </Route>
                <Route
                  exact
                  path="/download-xls"
                >
                  <Download />
                </Route>
                <Route
                  exact
                  path="/errorPage"
                >
                  <ErrorPage />
                </Route>
                {/** this is a fallback route */}
                <Redirect to="/errorPage" />
              </Switch>
              <Footer alwaysShow={ showFooter } />
            </div>
          </CSSTransition>
        </TransitionGroup>
      </ConfigProvider>
    </div>
  )
}

export default App
