import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useLocation } from 'react-router-dom'
import { useDispatch } from "react-redux"
import { showNavbar } from '@redux/actions'
import { getErrorMessage } from '@utils/Errors'
import { useAuthCookies } from '@hooks'
import './ErrorPage.scss'


const Page = (props) => {
  const {
    errorType,
  } = props
  const {
    t,
  } = useTranslation('errorsTranslation')
  const msg = getErrorMessage(errorType)

  return (
    <div className="page">
      <h2>
        { errorType }
      </h2>
      <p>
        { t(msg) }
      </p>
    </div>
  )
}

const ErrorPage = (props) => {
  const location = useLocation()
  const search_params = new URLSearchParams(location.search)
  const errorType = search_params.get('errorType')
  const dispatch = useDispatch()
  const { removeToken } = useAuthCookies()

  useEffect(() => {
    dispatch(showNavbar(false))
    return () => {
      dispatch(showNavbar(true))
    }
  }, [])

  let page
  if(errorType) {
    if(errorType === '102') {
      removeToken()
    }
    page = <Page errorType={errorType} />
  } else {
    page = (
      <div className="words">
        <h2>Sorry, something's wrong.</h2>
        <p>We will fix it as soon as possible.</p>
      </div>
    )
  }

  return (
    <div className="error-page wrapper">
      { page }
    </div>
  )
}

export default ErrorPage
