import React, {
  useCallback,
  useEffect,
  useState
} from 'react';
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom'
import { changeHeaderTitle } from '@redux/actions';
import {
  getTimeFormat,
  getChartData,
  getDivideValue,
} from '@utils/Numbers'
import {
  LineChart,
  TooltipWrap,
  Loading,
} from '@components';
import scrollTo from '@utils/Scroll';
import { getTrendList } from '@api'
import { useToggle } from '@hooks';
import './Trend.scss';

const Trend = (props) => {
  const {
    t,
    i18n,
  } = useTranslation('commonPhrases')
  const showValText = /zh/.test(i18n.language)
  const {
    animalID: animal_id,
  } = useParams()
  const [isLoading, toggleLoading] = useToggle(true)
  const [anchor, setAnchor] = useState('raw_concentration')
  const [trendData, setTrendData] = useState({})
  const [thresholds, setThresholds] = useState({})
  const dispatch = useDispatch()

  const getTrend = useCallback(async () => {
    try {
      const res = await getTrendList({ animal_id })
      const trendData = res.trends[0]
      dispatch(changeHeaderTitle(trendData.name))
      setTrendData(trendData)
      setThresholds(res.thresholds)
    } catch(err) {
      // do something
    } finally {
      toggleLoading(false)
    }
  }, [animal_id])

  useEffect(() => {
    getTrend()
  }, [])

  useEffect(() => {
    const el = document.getElementById(anchor)
    if(el) {
      const top = el.offsetTop - 54
      scrollTo(top, ()=>{} , 500)
    }
  }, [anchor])
  const handleAnchor = useCallback((navigator) => {
    return e => {
      setAnchor(navigator)
    }
  }, [])

  if(isLoading) {
    return (
      <Loading />
    )
  }

  const {
    name,
    age,
    arrival_date,
    buy_from,
    father,
    father_breed,
    mother,
    mother_breed,
    experiments,
    status,
  } = trendData

  const icon_all = status.all === 'normal' ? 'normal' : 'warning'

  const chartList = [
    'raw_concentration',
    'motile',
    'total_effective_sperm',
    'number_of_doses',
    'progressive',
  ]

  const yAxisUnits = {
    number_of_doses        : 'doseUnit',
    raw_concentration : 'concentrationUnit',
    motile            : "%",
    abnormality       : "%",
    progressive       : "%",
    total_effective_sperm    : 'spermCountUnit',
  }

  const timeFormat = getTimeFormat('day')
  const startDate = moment().tz(moment.tz.guess()).subtract(180, 'days').format(timeFormat)

  return (
    <div className="trend wrapper content-wrap">
      <div className="info-area">
        <div className="left">
          <div className="flex-wrap">
            <div className="name">
              <TooltipWrap
                tooltipOption={{
                  placement: 'bottomRight'
                }}
              >
                  { name }
              </TooltipWrap>
            </div>
            <p className="age">
             {
                t('age_withColon', {
                  age: [
                    Math.floor(age/12),
                    age%12,
                  ]
                })
              }
            </p>
          </div>
          <p className="tablet-hide">
            <i className={`${icon_all} aidmics-${icon_all}`} />
          </p>
        </div>
        <ul className="mid">
          <li>
            { t('source_farm_withColon', { buy_from }) }
          </li>
          <li>
            { t('arrival_date_withColon', { arrival_date }) }
          </li>
          <li>
            <div className="parent-breed">
              <p>
                { t('sire_withColon') }
              </p>
              <div className="breed-name">
                <TooltipWrap>
                  { father }
                </TooltipWrap>
              </div>
            </div>
          </li>
          <li>
            { t('sire_breed_withColon', { sire_breed: father_breed })}
          </li>
          <li>
            <div className="parent-breed">
              <p>
                { t('dam_withColon') }
              </p>
              <div className="breed-name">
                <TooltipWrap>
                  { mother }
                </TooltipWrap>
              </div>
            </div>
          </li>
          <li>
            { t('dam_breed_withColon', { dam_breed: mother_breed })}
          </li>
        </ul>
        <div className="right md-hide">
          <i className={`${icon_all} aidmics-${icon_all}`} />
          {
            showValText &&
            <span>
              { t('val_text') }
            </span>
          }
          <span>
            {
              status.all === 'normal' ?
                t('normal') :
                t('abnormal')
            }
          </span>
        </div>
      </div>
      <ul className="anchor-area md-hide">
        {
          chartList.map(chart => {
            return (
              <li
                className={`anchor ${anchor === chart ? 'active' : ''}`.trim()}
                onClick={ handleAnchor(chart) }
                key={chart}
              >
                <p>
                  { t(chart) }
                </p>
              </li>
            )
          })
        }
      </ul>
      <ul className="chart-area">
        {
          chartList.map(chart => {
            const data = getChartData(experiments, 'day', chart, startDate)
            const chartData = data.series || []
            const dataLength = chartData.length

            const divide = getDivideValue(chart)
            const threshold = thresholds[chart] ? thresholds[chart] / divide : null

            let yMax = 10
            if(dataLength) {
              yMax = Math.max(...chartData.map(d => d.y), threshold, yMax)
            }

            const xDomain = [0, 180]
            const yDomain = [0, yMax]
            const unit = yAxisUnits[chart]

            let type = 'default'
            if(chart === 'number_of_doses') {
              type = 'vertical-bar'
            }

            let icon = 'normal'
            let color = '#001B59'

            if(status[chart] === 'warning') {
              icon = 'warning'
              color = '#E33030'
            }
            
            
            return (
              <li
                className="card"
                id={chart}
                key={chart}
              >
                <div className="title">
                  <p className="trend-title">
                    { `${t(chart)} ${t('trend_chart')} (${t('half_year')})` }
                  </p>
                  <p className="advice md-hide">
                    { t('assessment') }
                  </p>
                  <p className="mark tablet-hide">
                    <i className={`${icon} aidmics-${icon}`} />
                    {
                      showValText &&
                      <span>
                        { t('val_text') }
                      </span>
                    }
                    <span>
                      {
                        status[chart] === 'warning' ?
                          t('abnormal') :
                          t('normal')
                      }
                    </span>
                  </p>
                </div>
                <div className="chart">
                  <div className="chart-wrap">
                    <LineChart
                      id={chart}
                      data={ data }
                      xAxis={ 'day' }
                      xDomain={ xDomain }
                      yDomain={ yDomain }
                      yAxisTitle={ chart }
                      yAxisUnit={ unit }
                      height={ 240 }
                      color={color}
                      chartType={type}
                      threshold={ threshold }
                    />
                  </div>
                  <p className="mark md-hide">
                    <i className={`${icon} aidmics-${icon}`} />
                    {
                      showValText &&
                      <span>
                        { t('val_text') }
                      </span>
                    }
                    <span>
                      {
                        status[chart] === 'warning' ?
                          t('abnormal') :
                          t('normal')
                      }
                    </span>
                  </p>
                </div>
              </li>
            )
          })
        }
      </ul>
    </div>
  );
}

export default Trend;
