import React, {
  useState,
  useCallback,
} from "react";
import { Tooltip, Typography } from "antd";
import "./TooltipWrap.scss"

const {
  Paragraph,
} = Typography

const TooltipWrap = (props) => {
  const {
    paragraphOption = {},
    tooltipOption = {},
    triggerNode = document.body,
    children,
  } = props
  const {
    ellipsis = {},
  } = paragraphOption
  const [truncated, setTruncated] = useState(false)

  const handlePopupContainer = useCallback(() => {
    return triggerNode
  }, [triggerNode])

  return (
    <Tooltip
      title={truncated ? children : undefined}
      {...tooltipOption}
      getPopupContainer={handlePopupContainer}
    >
      <Paragraph
        {...paragraphOption}
        ellipsis={{
          ...ellipsis,
          onEllipsis: setTruncated
        }}
      >
        <>
          { children }
        </>
      </Paragraph>
    </Tooltip>
  )
}

export default TooltipWrap
