import React, {
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import {
  Modal,
  Form,
  Input,
} from 'antd'
import { changeHeaderTitle } from '@redux/actions';
import { getErrorMessage } from '@utils/Errors'
import { forgetSpecies } from '@utils/Auth'
// components
import {
  TextField,
  WarningBar,
  Loading,
} from '@components';
import {
  getAccountInfo,
  updateAccountInfo,
  updatePassword,
  logout,
  deleteAccount,
} from '@api'
import {
  useToggle,
  useAsyncState,
  useAuthCookies,
} from '@hooks';
import './Account.scss';

const {
  Item,
  useForm,
} = Form

const Account = (props) => {
  const { t } = useTranslation(['accountTranslation'])
  const { setToken, removeToken } = useAuthCookies()
  const dispatch = useDispatch()
  const history = useHistory()
  const [isLoading, toggleLoading] = useToggle(true)
  const [isShowError, toggleError] = useToggle(false)
  const [isEditPassword, toggleEditPassword] = useToggle(false)
  const [errorMessage, setErrorMessage] = useAsyncState('')
  const [accountInfo, setAccountInfo] = useAsyncState({
    account: '',
    farmName: '',
    address: '',
    newPassword: '',
    oldPassword: '',
  })
  const initInfo = useRef({})

  const showError = useCallback((errorType) => {
    const errorMessage = getErrorMessage(errorType)
    setErrorMessage(errorMessage)
    toggleError(true)
  }, [])
  const fillInAccountInfo = useCallback((resp) => {
    const {
      accountInfo: resInfo,
    } = resp
    const {
      account,
      farmName,
      address,
    } = resInfo
    const load = {
      account,
      farmName,
      address,
    }
    setAccountInfo(preState => ({
      ...preState,
      ...load,
    }))
    initInfo.current = load
  }, [accountInfo])
  const fetchAccountInfo = useCallback(async() => {
    try {
      const res = await getAccountInfo()
      fillInAccountInfo(res)
    } catch(err) {
      showError(err.errorType)
    } finally {
      toggleLoading(false)
    }
  }, [])
  const handleChange = useCallback((group, key, value) => {
    setAccountInfo(preState => ({
      ...preState,
      [key]: value,
    }))
  }, [accountInfo])
  const editAccount = useCallback(async (mode, key, value) => {
    if(value === initInfo.current[key]) return
    toggleLoading(true)
    try {
      const res = await updateAccountInfo({
        [key]: value,
      })
      fillInAccountInfo(res)
      toggleError(false)
    } catch(err) {
      showError(err.errorType)
    } finally {
      toggleLoading(false)
    }
  }, [])
  const editPassword = useCallback(async () => {
    const {
      account,
      newPassword,
      oldPassword,
    } = accountInfo
    if(oldPassword === newPassword) {
      showError(207)
      return
    }
    toggleLoading(true)
    try{
      const res = await updatePassword({
        account,
        oldPassword,
        newPassword,
      })
      setToken(res.token)
      toggleError(false)
      toggleEditPassword(false)
      setAccountInfo(preState => ({
        ...preState,
        oldPassword: '',
        newPassword: '',
      }))
    } catch(err) {
      showError(err.errorType)
    } finally {
      toggleLoading(false)
    }
  }, [accountInfo])

  const navigateToLogin = useCallback(() => {
    removeToken()
      forgetSpecies()
      history.push('/login')
  }, [])
  const handleLogout = useCallback(async () => {
    toggleLoading(true)
    try {
      await logout()
      navigateToLogin()
    } catch(err) {
      showError(err.errorType)
    } finally {
      toggleLoading(false)
    }
  }, [])

  const [form] = useForm()

  const handleDelete = useCallback(async () => {
    Modal.confirm({
      maskClosable: true,
      centered: true,
      confirmLoading: true,
      title: t('delete_confirmation'),
      content: (
        <Form
          form={form}
          autoComplete="off"
        >
          <Item
            name="Email"
            validateFirst
            rules={[
              {
                required: true,
              },
              {
                type: 'email',
              },
              {
                pattern: new RegExp(`^${accountInfo.account}$`),
                message: `The entered email doesn't match your account.`,
              }
            ]}
          >
            <Input placeholder={t('accountPlaceholder')} />
          </Item>
        </Form>
      ),
      maskStyle: {
        backgroundColor: 'transparent'
      },
      okButtonProps: {
        type: 'aidmics-danger',
      },
      onOk: () => {
        return new Promise(async(res, rej) => {
          try {
            await form.validateFields()
            await deleteAccount()
            res()
            navigateToLogin()
          } catch(err) {
            // do nothing
            rej()
          }
        })
      },
      onCancel: () => {
        form.resetFields()
      }
    })
  }, [t, accountInfo.account])

  useEffect(() => {
    dispatch(changeHeaderTitle('account'))
    fetchAccountInfo()
  }, [])

  if(isLoading) {
    return (
      <Loading />
    )
  }

  return (
    <div className="account wrapper">
      <TextField
        id="account"
        title={ t('account') }
        placeholder={ t('accountPlaceholder') }
        group="account"
        editable={ true }
        value={ accountInfo.account }
        onChange={ handleChange }
        editDidFinish={ editAccount }
      />
      <div
        className={ "edit-password-wrapper" + ( isEditPassword ? " editing" : "") }
      >
        <TextField
          id="editPassword"
          title={ t('password') }
          group="account"
          editable={ true }
          className={ isEditPassword ? " hidden" : "" }
          value={ t('securedPassword') }
          onEdit={ toggleEditPassword }
          editDidFinish={ editAccount }
        />
        <TextField
          id="oldPassword"
          title={ t('oldPassword') }
          group="account"
          type="password"
          value={ accountInfo.oldPassword }
          onChange={ handleChange }
        />
        <TextField
          id="newPassword"
          title={ t('newPassword') }
          group="account"
          type="password"
          value={ accountInfo.newPassword }
          onChange={ handleChange }
        />
        <div
          className="edit-password-submit"
          onClick={ editPassword }
        >
          Change Password
        </div>
        <div
          className="edit-password-cancel"
          onClick={ toggleEditPassword }
        >
          Cancel
        </div>
      </div>
      <TextField
        id="farmName"
        title={ t('farmName') }
        placeholder={ t('farmNamePlaceholder') }
        group="account"
        editable={ true }
        value={ accountInfo.farmName }
        onChange={ handleChange }
        editDidFinish={ editAccount }
      />
      <TextField
        id="address"
        title={ t('address') }
        placeholder={ t('addressPlaceholder') }
        group="account"
        editable={ true }
        value={ accountInfo.address }
        onChange={ handleChange }
        editDidFinish={ editAccount }
      />
      <div className="submit-block">
        <button
          className="submit-button logout"
          onClick={handleLogout}
        >
          { t('logout') }
        </button>
        <button
          className="submit-button delete"
          onClick={handleDelete}
        >
          { t('delete') }
        </button>
      </div>
      <WarningBar
        text={ errorMessage }
        hidden={ !isShowError }
        onClick={ toggleError }
      />
    </div>
  )
}

export default Account;
