import validator from 'validator';

const isEmpty = (str) => {
  return typeof str !== 'string' || !str || validator.isEmpty(str);
}
const isEmail = (str) => {
  return typeof str === 'string' && str && validator.isEmail(str);
}

export {
  isEmpty,
  isEmail,
}