import React from 'react';
import DefaultSwitch from './DefaultSwitch';
import CollapsibleSwitch from './CollapsibleSwitch';
import './LanguageSwitch.scss';

const LanguageSwitch = (props) => {
  const {
    mode,
  } = props

  return (
    <div className="language-switch-container">
      {
        mode === 'collapse' ?
        <CollapsibleSwitch /> :
        <DefaultSwitch />
      }
    </div>
  )
}

export default LanguageSwitch;
