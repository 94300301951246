import React from 'react'
import {
  LanguageSwitch,
  SpeciesLabel,
} from '@components'
import './SimpleFooter.scss'

const SimpleFooter = (props) => {
  const { isVisible } = props

  return (
    <div className={`simple-footer${isVisible ? '' : ' hide'}`}>
      <SpeciesLabel />
      <span className="separate-bar" />
      <LanguageSwitch mode="collapse" />
    </div>
  )
}

export default SimpleFooter
