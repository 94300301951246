import React, {
  useRef,
  useCallback,
  useState,
} from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './CollapsibleSwitch.scss';


const CollapsibleSwitch = () => {
  const containerRef= useRef(null)
  const [isCollapsed, setIsCollapsed] = useState(true)
  const { i18n } = useTranslation()
  const {
    language,
  } = i18n

  const languages = useRef({
    zh_tw: '繁體中文',
    zh_cn: '简体中文',
    en: 'English',
  })

  const handleLanguageClick = useCallback((e) => {
    e.preventDefault()
    e.stopPropagation()
    if(e.target.classList.contains('open')) {
      const lang = e.target.dataset.lang
      i18n.changeLanguage(lang)
    }
    setIsCollapsed(pre => !pre)
  }, [])

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (containerRef.current && !containerRef.current.contains(e.target)) {
        setIsCollapsed(true)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <div
      ref={containerRef}
      className="language-collapsible-switch"
    >
      {
        Object.keys(languages.current).map((key, i) => {
          const classList = ['language-collapsible-switch-item']
          if(key === language) {
            classList.push('active-lang')
          }
          classList.push(`item-${i}`)
          classList.push(isCollapsed ? 'collapsed' : 'open')
          return (
            <div
              key={key}
              className={classList.join(' ')}
              data-lang={key}
              onClick={handleLanguageClick}
            >
              { languages.current[key] }
            </div>
          )
        })
      }
    </div>
  )
}

export default CollapsibleSwitch;
