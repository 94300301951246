import React, { useEffect } from "react";
import {
  Route,
  Redirect,
  useHistory,
} from 'react-router-dom'
import {
  useDispatch,
  useSelector,
} from 'react-redux'
import { showBackButton } from '@redux/actions';
import {
  useAsyncState,
  useAuthCookies,
} from '@hooks'

const PrivateRoute = ({ children, goBack, ...restProps}) => {
  const {
    location,
  } = restProps
  const {
    pathname,
    search,
  } = location
  const search_params = new URLSearchParams(search)
  const animal_id = search_params.get('animal_id')
  const { token } = useAuthCookies()
  const [isFetch, setIsFetch] = useAsyncState(true)
  const dispatch = useDispatch()
  const history = useHistory()
  const currentSpecies = useSelector(state => state.species.currentSpecies)

  useEffect(() => {
    window.scrollTo(0, 0)
    const shouldShowGoBack = !!goBack || !!animal_id
    dispatch(showBackButton(shouldShowGoBack))
    const reg = /account|species/
    // prevent router infinite loops
    if(currentSpecies !== 'WW' || reg.test(pathname) || !token) {
      setIsFetch(false)
      return
    }
    if(currentSpecies === 'WW' && pathname !== '/species') {
      history.push({
        pathname: '/species',
        state: {
          from: `${pathname}${search}`,
        }
      })
    }
  }, [token])

  // tricky part
  if(isFetch) {
    return (<noscript></noscript>)
  }

  return (
    <Route {...restProps}>
      {
        token ?
        children :
        <Redirect
          to={{
            pathname: '/login',
            state: {
              from: `${pathname}${search}`,
            }
          }}
        />
      }
    </Route>
  )
}

export default PrivateRoute
