import React, {
  useCallback,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { changeHeaderTitle } from '@redux/actions';
import { getRecommendation } from '@api'
import {
  useToggle,
  useAsyncState,
} from '@hooks';
import {
  Loading,
  TooltipWrap,
} from '@components';
import './SemenOrder.scss';

const SemenOrder = (props) => {
  const { t } = useTranslation('commonPhrases')
  const dispatch = useDispatch()
  const [isLoading, toggleLoading] = useToggle(true)
  const [recommendationList, setRecommendationList] = useAsyncState([])

  const getRecommendationList = useCallback(async () => {
    try {
      const res = await getRecommendation()
      setRecommendationList(res.recommendation)
    } catch(err) {
      // do something
    } finally {
      toggleLoading(false)
    }
  }, [])
  
  useEffect(() => {
    dispatch(changeHeaderTitle('semenOrder'))
    getRecommendationList()
  }, [])

  if(isLoading) {
    return (
      <Loading />
    )
  }

  const statusMap = {
    good: {
      icon: 'crown',
      text: t('state_good'),
      class: 'good-highlight'
    },
    normal: {
      text: t('state_average'),
    },
    warning: {
      icon: 'warning',
      text: t('state_warn'),
      class: 'warn-highlight',
    },
  }
  
  return (
    <div className="semen-order wrapper">
      <div className="mark-area sticky">
        <p>
          { t('total', { total: recommendationList.length })}
        </p>
      </div>
      <ul className="order-list">
        <li className="order-list-item sticky content-wrap">
          <p className="item order">
            { t('order') }
          </p>
          <p className="item name">
            { t('swine_name') }
          </p>
          <p className="item days">
            { t('last_collection_days') }
          </p>
          <p className="item quality">
            { t('sperm_quality') }
          </p>
        </li>
        {
          recommendationList.map((list, index) => {
            const {
              name,
              status, // good, normal, warning
              time_since_last_collection,
            } = list
            
            const highlight = statusMap[status].class || ''
            const icon = statusMap[status].icon
            const text = statusMap[status].text

            return (
              <li className={ `order-list-item content-wrap ${highlight}`.trim() }
                key={ name }
              >
                <div className="item order">
                  <div className={ `order-wrap ${icon ? 'with-icon' : ''}`.trim() }>
                    {
                      icon &&
                      <i className={`${icon} aidmics-${icon}`} />
                    }
                    <span>
                      { `${index + 1}` }
                    </span>
                  </div>
                </div>
                <div className="item name">
                  <TooltipWrap>
                    { name }
                  </TooltipWrap>
                </div>
                <p className="item days">
                  { t('days', { days: time_since_last_collection }) }
                </p>
                <p className="item quality">
                  { text }
                </p>
              </li>
            )
          })
        }
      </ul>
    </div>
  );
}

export default SemenOrder;
