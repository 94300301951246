import {
  getExperimentList,
  getAnimalList,
  getTrendList,
} from '@api'

// Navbar
export const toggleMenu = () => {
  return {
    type: 'TOGGLE_MENU'
  }
}
export const hideMenu = () => {
  return {
    type: 'HIDE_MENU'
  }
}
export const showNavbar = (shouldShow) => {
  return {
    type: 'SHOW_NAVBAR',
    shouldShow: shouldShow,
  }
}
export const showBackButton = (shouldShow) => {
  return {
    type: 'SHOW_BACK_BUTTON',
    shouldShowBack: shouldShow,
  }
}

export const changeHeaderTitle = (title = 'iSperm Cloud') => {
  return {
    type: 'CHANGE_HEADER_TITLE',
    headerTitle: title
  }
}

// Species

export const changeSpecies = (species) => {
  return {
    type: 'CHANGE_SPECIES',
    species: species
  }
}
export const receiveSpeciesList = (speciesList) => {
  return {
    type: 'RECEIVE_SPECIES_LIST',
    speciesList: speciesList
  }
}
// Chart

export const changeX = (xAxis) => {
  return {
    type: 'CHANGE_X',
    xAxis: xAxis,
  }
}
export const changeY = (yAxis) => {
  return {
    type: 'CHANGE_Y',
    yAxis: yAxis,
  }
}

// Data

// Measurement List
export const receiveExperimentList = (experimentList) => {
  return {
    type: 'RECEIVE_MEASUREMENT_LIST',
    experimentList: experimentList,
  }
}
export const fetchExperimentList = () => {
  return async (dispatch) => {
    try {
      const res = await getExperimentList()
      dispatch(changeHeaderTitle('experiments'))
      dispatch(receiveExperimentList(res.experiments))
    } catch(err) {
      // TODO: handle error
      console.log(err)
    }
  }
}

// Animal List
export const receiveAnimalList = (animalList) => {
  return {
    type: 'RECEIVE_ANIMAL_LIST',
    animalList: animalList,
  }
}
export const fetchAnimalList = () => {
  return async (dispatch) => {
    try {
      const res = await getAnimalList()
      dispatch(receiveAnimalList(res.animals))
    } catch(err) {
      // TODO: handle error
      console.log(err)
    }
  }
}

// Single Animal ("Find this poor animal a mate!")
export const receiveAnimalData = (animalData) => {
  return {
    type: 'RECEIVE_ANIMAL_DATA',
    animalData: animalData,
  }
}
export const fetchExperimentById = (animalID) => {
  return async (dispatch) => {
    try {
      const res = await getExperimentList(animalID)
      const data = res.experiments
      const name = data[0].animal_name
      dispatch(changeHeaderTitle(name))
      dispatch(receiveAnimalData(data))
    } catch(err) {
      // TODO: handle error
      console.log(err)
    }
  }
}

export const sort = (target, sortBy) => {
  return {
    type: 'SORT',
    target: target,
    sortBy: sortBy,
  }
}

// Account
export const recordFPEmail = (email) => {
  return {
    type: 'RECORD_FP_EMAIL',
    email: email,
  }
}

export const setProAuth = (auth) => {
  return {
    type: 'SET_PRO_AUTH',
    auth,
  }
}

// Trend Report List
export const cacheTrendReport = ({ trends, thresholds }) => {
  return {
    type: 'CACHE_TREND_REPORT',
    trends,
    thresholds,
  }
}
export const fetchTrendReportList = ({ order_by }) => {
  return async (dispatch) => {
    try {
      const res = await getTrendList({ order_by })
      const {
        trends,
        thresholds,
      } = res

      dispatch(cacheTrendReport({
        trends,
        thresholds,
      }))
    } catch(err) {
      // do something
    }
  }
}
export const setAnchor = (anchor) => {
  return {
    type: 'SET_ANCHOR',
    anchor,
  }
}

export const setPageNumber = (page) => {
  return {
    type: 'SET_PAGE_NUMBER',
    page,
  }
}

export const setPageSize = (size) => {
  return {
    type: 'SET_PAGE_SIZE',
    size,
  }
}

export const resetTrendReport = () => {
  return {
    type: 'RESET_TREND_REPORT'
  }
}
