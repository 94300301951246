import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { Cookies } from 'react-cookie';

import * as transEn from './lang/en'
import * as transCn from './lang/zh_cn'
import * as transTw from './lang/zh_tw'

const languages = [
  'en',
  'zh_tw',
  'zh_cn',
]

const cookie = new Cookies()

let language = cookie.get('language');
if(!language) {
  language = navigator.languages ? navigator.languages[0]
  : (navigator.language || navigator.userLanguage);
  language = language.replace('-', '_').toLowerCase();
}

const enabledLanguage = languages.includes(language) ? language : 'en'

const resources = {
  en: {
    ...transEn,
  },
  zh_tw: {
    ...transTw,
  },
  zh_cn: {
    ...transCn,
  },
}

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: enabledLanguage,
    fallbackLng: 'en',
    defaultNS: 'commonPhrases',
    whitelist: languages,
    interpolation: {
      escapeValue: false,
    }
  }, (err, t) => {
    if(err) {
      return console.log(`something went wrong ${err}`)
    }
  })

export default i18n
