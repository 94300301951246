import React from 'react';
import {
  LanguageSwitch,
  SpeciesLabel,
} from '@components';
import './Footer.scss';

const Footer = (props) => {
  const { alwaysShow } = props

  return (
    <div className={`footer ${alwaysShow ? ' alwaysShow' : ''}`}>
      <div className="footer-content content-wrap">
        <SpeciesLabel />
        <span className="separate-bar" />
        <LanguageSwitch />
      </div>
    </div>
  )
}

export default Footer
