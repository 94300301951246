import moment from 'moment';
import { camel2Snake } from '@utils/Strings'
import i18n from '@/i18n';

const getTimeFormat = (scale) => {
  var format;
  switch(scale) {
    case "day":
      format = "YYYY-MM-DD";
      break;
    case "week":
      format = "gggg[, week] ww";
      break;
    case "month":
      format = "YYYY-MM";
      break;
    default:
      break
  }
  return format;
}
const round = (num) => {
  if(typeof num !== "number") return 0;
  else return Math.round(num * 100)/100;
}
const average = (arr) => {
  const total = arr.reduce((pre, cur) => {
    if(isNaN(cur)) return pre
    return pre + cur
  }, 0);
  return total/arr.length;
}
const getDivideValue = (target) => {
  const {
    language,
  } = i18n
  const isChinese = language === 'zh_tw' || language === 'zh_cn'
  let divide = 1

  switch(target) {
    case 'concentration_avg':
    case 'measured_concentration':
    case 'raw_concentration':
      if(isChinese) {
        divide = 100
      }
      break
    case 'total_sperm':
    case 'total_effective_sperm':
    case 'dose_effective_sperm':
      if(isChinese) {
        divide = 100
      } else {
        divide = 1000
      }
      break
    default:
      break
  }
  return divide
}


const  getChartData = (data, xAxis, yAxis, startDate) => {
  const target = camel2Snake(yAxis)

  let startFrom = startDate
  if(!startDate) {
    const dates = data.map(d => moment(d.timestamp))
    startFrom = moment.min(dates);
  }

  // compare date using day format.
  const momentFrom = moment(startFrom)
  const formattedDate = momentFrom.format('YYYY-MM-DD')
  const hash = {}
  data.forEach((d, i) => {
    const date = moment(d.timestamp).format('YYYY-MM-DD')
    const x = moment(date).diff(formattedDate, xAxis)
    const y = d[target]
    if(!hash[x]) {
      hash[x] = []
    }
    hash[x].push(y)
  })
  const divide = getDivideValue(target)
  const chartData = Object.keys(hash).map(key => {
    const valY = average(hash[key])
    return {
      x: Number(key),
      y: valY / divide
    }
  })
  return {
    series: chartData,
    startDate: momentFrom
  }
}

const getXAxisTicks = (xDomain, width, tickPadding = 85) => {
  if(!xDomain.length || !width) return []
  const xTicks = []
  const maxTickNum = Math.floor( (width - 100) / tickPadding)
  const iterators = xDomain[1] - xDomain[0]
  const step = Math.ceil( (iterators + 1) / maxTickNum)
  for(let i = 0; i < iterators; i += step) {
    xTicks.push(i)
  }

  return xTicks
}

export {
  getTimeFormat,
  round,
  average,
  getDivideValue,
  getXAxisTicks,
  getChartData,
}





