import React, { useCallback } from 'react';
import { withTranslation } from 'react-i18next'
// components
import {
  TextField,
  SubmitButton,
} from '@components';
import logo from '@/assets/images/logo.svg'

const ResetPasswordSubview = (props) => {
  const {
    t,
    hidden,
    onChange,
    onSubmit,
  } = props
  const onKeyPress = useCallback((e) => {
    if(e.key === 'Enter') {
      onSubmit()
    }
  })
  return (
    <div className={ "forgot-password" + (hidden ? " hidden" : "") }>
      <img
        className="logo"
        src={logo}
        alt="logo"
      />
      <div className="title">
        { t('resetPassword') }
      </div>
      <div className="description">
        { t('resetPasswordDesc') }
      </div>
      <TextField
        id="newPassword"
        title={ t('password') }
        group="resetPassword"
        placeholder={ t('passwordPlaceholder') }
        type="password"
        onChange={ onChange }
        onKeyPress={ onKeyPress }
      />
      <SubmitButton onSubmit={ onSubmit }>
        { t('submit') }
      </SubmitButton>
    </div>
  )
}

export default withTranslation('accountTranslation')(ResetPasswordSubview);
