import React, {
  useCallback,
  useEffect,
  useRef,
} from 'react';
import {
  useHistory,
  useParams,
} from 'react-router-dom';
import { withTranslation } from 'react-i18next'
import {
  DatePicker,
  Modal,
} from 'antd'
import moment from 'moment';
import {
  Loading,
  Field,
} from '@components';
import {
  changeHeaderTitle,
} from '@redux/actions'
import { useDispatch } from 'react-redux';
import {
  useToggle,
  useAsyncState,
} from '@hooks';
import {
  getAnimalById,
  updateAnimalById,
  deleteAnimalById,
} from '@api'
import './AnimalInfo.scss'

const Picker = (props) => {
  const dateFormat = 'YYYY-MM-DD'
  const {
    value,
    handleDateChange,
    isDisabled,
  } = props
  
  const disabledDate = (currentDate) => {
    return currentDate && (
      currentDate > moment().endOf('day') ||
      currentDate < moment(0).startOf('day'))
  }
  return (
    <DatePicker
      size="large"
      defaultValue={!!value ? moment(value, dateFormat) : value}
      format={dateFormat}
      disabled={isDisabled}
      disabledDate={disabledDate}
      onChange={handleDateChange}
    />
  )
}

const AnimalInfo = (props) => {
  const {
    t,
  } = props
  const dispatch = useDispatch()
  const history = useHistory()
  const {
    animalID: animal_id,
  } = useParams()
  const [isLoading, toggleLoading] = useToggle(true)
  const [isModalVisible, toggleIsModalVisible] = useToggle(false)
  const [animalInfo, setAnimalInfo] = useAsyncState({})
  const tempKey = useRef(null)
  const infoBeforeUpdate = useRef(null)
  const handleOnEdit = useCallback((name) => {
    tempKey.current = name
  }, [])
  const handleChange = useCallback(e => {
    e.preventDefault()
    let {
      value,
      name,
    } = e.target
    if(name === 'price') {
      value = value.replace(/\D/g, '')
    }

    setAnimalInfo(preState => {
      return {
        ...preState,
        [name]: value
      }
    })
  }, [])
  const handleDateChange = (date, dateString) => {
    setAnimalInfo(preState => {
      return {
        ...preState,
        birth_date: dateString,
      }
    })
  }
  const handleSubmit = useCallback(async () => {
    const val = animalInfo[tempKey.current]
    const preVal = infoBeforeUpdate.current[tempKey.current]
    if(preVal === val) return
    toggleLoading(true)
    try {
      const data = {
        [tempKey.current]: val
      }
      await updateAnimalById(data, animal_id)
    } catch(err) {
      console.log(err)
    } finally {
      toggleLoading(false)
    }
  }, [animalInfo, animal_id])
  const handleDelete = async () => {
    toggleIsModalVisible()
    toggleLoading(true)
    try {
      await deleteAnimalById(animal_id)
      history.goBack()
    } catch(err) {
      console.log(err)
    } finally {
      toggleLoading(false)
    }
  }

  const fetchAnimalById = useCallback(async () => {
    try {
      const res = await getAnimalById(animal_id);
      dispatch(changeHeaderTitle(res.animal_name))
      setAnimalInfo(res);
      infoBeforeUpdate.current = res
    } catch(err) {
      console.log(err);
    } finally {
      toggleLoading(false);
    }
  }, [animal_id])
  const sequence = [
    {
      key: 'animal_name',
      lang_key: 'animal_name',
      maxLength: 100,
    },
    {
      key: 'birth_date',
      lang_key: 'age',
      maxLength: 100,
    },
    {
      key: 'breed',
      lang_key: 'breed',
      maxLength: 100,
    },
    {
      key: 'buy_from',
      lang_key: 'source_farm',
      maxLength: 100,
    },
    {
      key: 'price',
      lang_key: 'price',
      maxLength: 9,
    },
    {
      key: 'stall',
      lang_key: 'stall',
      maxLength: 100,
    },
    {
      key: 'father',
      lang_key: 'sire',
      maxLength: 100,
    },
    {
      key: 'father_breed',
      lang_key: 'sire_breed',
      maxLength: 100,
    },
    {
      key: 'mother',
      lang_key: 'dam',
      maxLength: 100,
    },
    {
      key: 'mother_breed',
      lang_key: 'dam_breed',
      maxLength: 100,
    },
  ]
  useEffect(() => {
    fetchAnimalById()
  }, [animal_id])

  if(isLoading) {
    return (
      <Loading />
    )
  }

  return (
    <div className="animal-info wrapper">
      <Modal
        title={ (
          <span className="modal-title">
            <i className="aidmics-warning" />
            { t('notice') }
          </span>
        )  }
        visible={ isModalVisible }
        centered
        closable={false}
        onOk={handleDelete}
        onCancel={toggleIsModalVisible}
      >
        <p>
          { t('delete_animal') }
        </p>
      </Modal>
      {
        sequence.map(sq => {
          const {
            key,
            lang_key,
            maxLength,
          } = sq
          if(key === 'birth_date') {
            return (
              <Field
                title={ t(lang_key) }
                name={key}
                handleSubmit={handleSubmit}
                handleOnEdit={handleOnEdit}
                key={key}
              >
                <Picker
                  value={animalInfo[key]}
                  handleDateChange={handleDateChange}
                />
              </Field>
            )
          }
          return(
            <Field
              value={animalInfo[key]}
              name={key}
              title={ t(lang_key) }
              maxLength={maxLength}
              placeholder={ t(`${lang_key}_placeholder`) }
              handleOnEdit={handleOnEdit}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              key={key}
            />
          )
        })
      }
      <div className="delete-block">
        <button onClick={toggleIsModalVisible}>
          { t('delete') }
        </button>
      </div>
    </div>
  )
}

export default withTranslation('commonPhrases')(AnimalInfo);
