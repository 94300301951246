import React, { Component } from 'react';
import './Download.scss';

class Download extends Component {
  render() {
    return (
      <div id="download" className="wrapper">
      </div>
    );
  }
}

export default Download;

