import { Cookies } from 'react-cookie';
import { checkProAuth } from '@utils/Auth'

const cookie = new Cookies()

// Menu
let initialMenuState = {
  mobileMenuShown: false,
  shouldShow: true,
  shouldShowBack: false,
  headerTitle: 'iSperm Cloud'
}
export function navbar(state = initialMenuState, action) {
  switch (action.type) {
    case 'TOGGLE_MENU':
      return {
        ...state,
        mobileMenuShown: !state.mobileMenuShown
      }
    case 'HIDE_MENU':
      return {
        ...state,
        mobileMenuShown: false
      }
    case 'SHOW_NAVBAR':
      return {
        ...state,
        shouldShow: action.shouldShow,
      }
    case 'SHOW_BACK_BUTTON':
      return {
        ...state,
        shouldShowBack: action.shouldShowBack,
      }
    case 'CHANGE_HEADER_TITLE':
      return {
        ...state,
        headerTitle: action.headerTitle
      }
    default:
      return state
  }
}

// Species
let initialSpeciesState = {
  // Look up in cookies, because this is how app tells its webView secrets
  // But DO NOT save species in cookies anywhere else.
  currentSpecies: cookie.get('species') || "WW",
  allSpecies: [],
}
export function species(state = initialSpeciesState, action) {
  switch (action.type) {
    case 'CHANGE_SPECIES':
      return {
        ...state,
        currentSpecies: action.species
      }
    case 'RECEIVE_SPECIES_LIST':
      return {
        ...state,
        allSpecies: action.speciesList
      }
    default:
      return state
  }
}

// Chart
let initialChartState = {
  xAxis: "day",
  yAxis: "raw_concentration",
}
export function chart(state = initialChartState, action) {
  switch (action.type) {
    case 'CHANGE_X':
      return {
        ...state,
        xAxis: action.xAxis,
      }
    case 'CHANGE_Y':
      return {
        ...state,
        yAxis: action.yAxis,
      }
    default:
      return state
  }
}

// Fetch Data
let initialDataState = {
  sortBy: "timestamp",
  experimentList: null,
  animalList: null,
  animalData: null,
}
const sort = (data, sortBy) => {
  if(!data) return data;
  data.sort((a, b) => {
    if(typeof a[sortBy] === "string") {
      return b[sortBy].localeCompare(a[sortBy]);
    }
    if (typeof a[sortBy] === "number") {
      return b[sortBy] - a[sortBy];
    }
    return b["timestamp"].localeCompare(a["timestamp"])
  });
  return data;
}
export function data(state = initialDataState, action) {
  switch (action.type) {
    // `target` is an array. Each element is a key inside `state`.
    // This way we can go deep into the one needs sorting.
    case 'SORT':
      // to prevent mutating state
      const data = state[action.target].slice()
      let sortBy = action.sortBy;
      if(data[0] === undefined || data[0][sortBy] === undefined) sortBy = "timestamp";
      const sortedData = sort(data, sortBy)
      
      return {
        ...state,
        sortBy,
        [action.target]: sortedData,
      }
    case 'RECEIVE_MEASUREMENT_LIST': {
      const sortBy = (action.experimentList[0]
                      && action.experimentList[0][state.sortBy] !== undefined)
      ? state.sortBy : "timestamp";
      return {
        ...state,
        sortBy: sortBy,
        experimentList: sort(action.experimentList, sortBy),
      }
    }
    case 'RECEIVE_ANIMAL_LIST': {
      const sortBy = (action.animalList[0]
                   && action.animalList[0][state.sortBy] !== undefined)
      ? state.sortBy : "timestamp";
      return {
        ...state,
        sortBy: sortBy,
        animalList: sort(action.animalList, state.sortBy),
      }
    }
    case 'RECEIVE_ANIMAL_DATA': {
      const sortBy = (action.animalData[0]
                      && action.animalData[0][state.sortBy] !== undefined)
      ? state.sortBy : "timestamp";
      const animalData = sort(action.animalData, state.sortBy)
      return {
        ...state,
        sortBy: sortBy,
        animalData: animalData,
      }
    }
    case 'CLEAR_ANIMAL_DATA': {
      return {
        ...state,
        animalData: {},
      }
    }
    default:
      return state;
  }
}

// Account
let initialAccountState = {
  forgotPassEmail: "",
  hasProAuth: checkProAuth()
}
export function account(state = initialAccountState, action) {
  switch (action.type) {
    case 'RECORD_FP_EMAIL':
      return {
        ...state,
        forgotPassEmail: action.email,
      }
    case 'SET_PRO_AUTH':
      return {
        ...state,
        hasProAuth: action.auth,
      }
    default:
      return state;
  }
}

// Trend Report List
let initialTrendReportList = {
  trendReportList: null,
  thresholds: null,
  anchor: null,
  pageNumber: 1,
  pageSize: 20,
}

export function trendReport(state = initialTrendReportList, action) {
  switch (action.type) {
    case 'CACHE_TREND_REPORT':
      const {
        trends,
        thresholds,
      } = action
      return {
        ...state,
        trendReportList: trends,
        thresholds,
      }
    case 'SET_ANCHOR': {
      return {
        ...state,
        anchor: action.anchor,
      }
    }
    case 'SET_PAGE_NUMBER': {
      return {
        ...state,
        pageNumber: action.page,
      }
    }
    case 'SET_PAGE_SIZE': {
      return {
        ...state,
        pageSize: action.size,
      }
    }
    case 'RESET_TREND_REPORT' : {
      return {
        ...state,
        anchor: null,
        pageNumber: 1,
        pageSize: 20,
      }
    }
    default:
      return state
  }
}
