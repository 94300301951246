import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import {
  FlexibleWidthXYPlot,
  XAxis,
  YAxis,
  HorizontalGridLines,
  VerticalBarSeries,
  LineMarkSeries,
  LineSeries,
  Hint,
  GradientDefs,
  AreaSeries,
} from 'react-vis';
import { withTranslation } from 'react-i18next';
import {
  getXAxisTicks,
  round,
  getTimeFormat,
} from '@utils/Numbers'
import { useSizeObserve } from '@hooks'
import './LineChart.scss';

const LineChart = (props) => {
  const {
    t,
    id = '', // this is to specify that all the gradient id should be unique
    data,
    xAxis,
    showXAxis = true,
    xDomain,
    showYAxis = true,
    yDomain,
    showGridLine = true,
    yAxisTitle,
    yAxisUnit,
    timeFormat,
    height = 300,
    tickPadding,
    threshold,
    chartType = 'default',
    color = '#001b59',
    errorText = 'notEnoughData', // custom error text could be passed
  } = props
  const {
    observer,
    width,
  } = useSizeObserve()
  const [hintValue, setHintValue] = useState(null)
  const charRef = useRef(null)

  useEffect(() => {
    observer.observe(charRef.current)
    return () => {
      observer.disconnect()
    }
  }, [])

  const getDomains = useMemo(() => {
    const chartData = data.series
    // return default domains when there's not enough data
    if(!chartData.length) {
      return {
        xDomainSet: [0, 180],
        yDomainSet: [0, 10],
      }
    }
    let xDomainSet = xDomain
    if(!xDomain) {
      const xArray = chartData.map(d => d.x)
      xDomainSet = [
        Math.min(...xArray, 0),
        Math.max(...xArray),
      ]
    }
    let yDomainSet = yDomain
    if(!yDomain) {
      let yArray = chartData.map(d => d.y)
      if(threshold) {
        yArray = [...yArray, threshold]
      }
      yDomainSet = [
        Math.min(...yArray, 0),
        Math.max(...yArray, 10),
      ]
    }

    return {
      xDomainSet,
      yDomainSet
    }
  }, [data, xDomain, yDomain, threshold])

  const {
    xDomainSet,
    yDomainSet,
  } = getDomains

  const generateXTicks = useMemo(() => {
    const {
      xDomainSet,
    } = getDomains
    return getXAxisTicks(xDomainSet, width, tickPadding)
  }, [getDomains, width, tickPadding])

  const changeHintValue = useCallback((value) => {
    setHintValue(value)
  }, [])
  const resetHintValue = useCallback(() => {
    setHintValue(null)
  }, [])

  // reset hint value every time xAxis or yAxis change
  useEffect(() => {
    resetHintValue()
  }, [xAxis, yAxisTitle])

  const xTickFormat = useCallback((x) => {
    let format = timeFormat || getTimeFormat(xAxis)
    const startDate = data.startDate.clone()
    return startDate.add(x, xAxis+"s").format(format)
  }, [data, timeFormat, xAxis])

  const margin = {
    left: showYAxis ? 50 : 20,
    right: 20,
  }

  let chartData = data.series
  let errorMessage
  let hint
  let thresholdData
  let Series
  let Gradient
  let ThresholdArea
  let ThresholdLine
  if(!chartData.length) {
    errorMessage = 'noExperiments'
  } else if(chartData.length < 2) {
    errorMessage = errorText
  } else {
    hint = hintValue && (
      <Hint value={ hintValue } className={ hintValue ? "" : "hidden" } animation={ true } >
        <div>
          <label>{ `${t('average')}${t(yAxisTitle)}` }</label>
          <p>{ round(hintValue.y) } { t(yAxisUnit) }</p>
          <hr/>
          <p>{ xTickFormat(hintValue.x) }</p>
        </div>
      </Hint>
    )

    // Threshold line
    if(threshold) {
      thresholdData = xDomainSet.map(coordinateX => {
        return {
          x: coordinateX,
          y: threshold
        }
      })
      
      chartData = chartData.map(d => {
        const thresholdColor = d.y > threshold ? '#001b59' : '#E33030'
        return {
          ...d,
          color: thresholdColor,
        }
      })

      Gradient = (
        <GradientDefs>
          <linearGradient id={`${id}-gradient`} x1="0" x2="0" y1="0" y2="1">
            <stop offset="0%" stopColor={color} stopOpacity={0.3} />
            <stop offset="100%" stopColor="#fff" stopOpacity={0.3} />
          </linearGradient>
        </GradientDefs>
      )

      ThresholdArea = (
        <AreaSeries
          className="threshold-area"
          color={`url(#${id}-gradient)`}
          data={ thresholdData }
        />
      )

      ThresholdLine = (
        <LineSeries
          className="threshold-line"
          data={ thresholdData }
          stroke={color}
          strokeStyle="dashed"
        />
      )
    }

    switch(chartType) {
      case 'vertical-bar':
        Series = (
          <VerticalBarSeries
            onValueMouseOver={ changeHintValue }
            onValueClick={ changeHintValue }
            onValueMouseOut={ resetHintValue }
            data={ chartData }
            animation
            color={color}
          />
        )
        break
      case 'line':
        Series = (
          <LineSeries
            color={color}
            data={ chartData }
          />
        )
        break
      default:
        Series = (
          <LineMarkSeries
            onValueMouseOver={ changeHintValue }
            onValueClick={ changeHintValue }
            onValueMouseOut={ resetHintValue }
            data={ chartData }
            lineStyle={{stroke: '#001b59'}}
            markStyle={{fill: '#fff'}}
            colorType="literal"
            color={color}
            fill="#fff"
            animation
          />
        )
        break
    }
  }

  // TODO: fix multi series onValueMouseOut problem see: https://github.com/uber/react-vis/issues/518

  return (
    <div className="line-chart"
      style={{
        maxHeight: `${height + 40}px`
      }}
      ref={charRef}
      onClick={ resetHintValue }
    >
      {
        showYAxis &&
        <label className="unit">
          {`${t('average')}${t(yAxisTitle)}${yAxisUnit ? `(${t(yAxisUnit)})` : ''}`}
        </label>
      }
      <FlexibleWidthXYPlot
        dontCheckIfEmpty
        xDomain={ xDomainSet }
        yDomain={ yDomainSet }
        margin={ margin }
        height={ height }
        animation
      >
        { Gradient }
        { ThresholdArea }
        { ThresholdLine }
        {
          showGridLine &&
          <HorizontalGridLines />
        }
        {
          showXAxis &&
          <XAxis
            hideTicks={ !!errorMessage }
            tickFormat={ xTickFormat }
            tickValues={ generateXTicks }
          />
        }
        {
          showYAxis &&
          <YAxis
            hideTicks={ !!errorMessage }
          />
        }
        { Series }
        { hint }
      </FlexibleWidthXYPlot>
      {
        errorMessage &&
        <p className="hint">
          { t(errorMessage) }
        </p>
      }
    </div>
  );
}

export default withTranslation()(LineChart);
