import React, {
  useRef,
  useCallback
} from 'react';
import { useTranslation } from 'react-i18next';
import './DefaultSwitch.scss';

const DefaultSwitch = () => {
  const { i18n } = useTranslation()
  const {
    language,
  } = i18n

  const languages = useRef({
    zh_tw: '繁體中文',
    zh_cn: '简体中文',
    en: 'English',
  })

  const selectLanguage = useCallback((e) => {
    e.preventDefault()
    const lang = e.target.dataset.lang
    i18n.changeLanguage(lang)
  }, [i18n])

  return (
    <ul className="language-default-switch">
      {
        Object.keys(languages.current).map(key => {
          return (
            <li
              key={key}
              className={`language-default-switch-item${key === language ? ' active-lang' : ''}`}
              data-lang={key}
              onClick={selectLanguage}
            >
              { languages.current[key] }
            </li>
          )
        })
      }
    </ul>
  )
}

export default DefaultSwitch;
