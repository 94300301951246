import {
  useRef,
  useState,
  useCallback,
} from 'react'

const usePositionedPopup = () => {
  const popupRef = useRef(null)
  const [isPopupVisible, setIsPopupVisible] = useState(false)

  const setTransformOrigin = useCallback((el) => {
    if(!popupRef.current || !el) return
    const {
      top,
      left,
    } = el.getBoundingClientRect()
    
    const {
      clientHeight,
      clientWidth,
    } = el
    
    popupRef.current.style.transformOrigin = `${left + clientWidth/2}px ${top + clientHeight/2}px`
  }, [])

  return {
    popupRef,
    isPopupVisible,
    setIsPopupVisible,
    setTransformOrigin,
  }
}

export default usePositionedPopup
