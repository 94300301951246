import { useCallback } from 'react'
import { useCookies } from 'react-cookie'

/**
 * cookie manipulation should use the useCookies hook,
 * so that cookies would be reactive.
 */
const useAuthCookies = () => {
  const [cookie, setCookie, removeCookie] = useCookies(['token'])
  const setToken = useCallback((token) => {
    setCookie('token', token, { path: '/' })
  }, [cookie])

  const removeToken = useCallback(() => {
    removeCookie('token', { path: '/' })
  }, [cookie])
  
  return {
    token: cookie.token,
    setToken,
    removeToken,
  }
}

export default useAuthCookies
