import axios from 'axios'
import history from '@/historyRef'
import { getToken } from '@utils/Auth';

const http = axios.create({
  baseURL: process.env.REACT_APP_API_DOMAIN,
  // TODO: wait till time selector feature is on
  // timeout: 10000,
})

function onRequest(config) {
  const token = getToken()
  if(!!token) {
    config.headers.Authorization = `Token ${token}`
  }
  return config
}

function onRequestError(error) {
  return Promise.reject(error)
}

http.interceptors.request.use(onRequest, onRequestError)


function onResponse(response) {
  if(response.data.success) return response.data
  const {
    errorType,
  } = response.data

  const reg = /100|101|102|404/

  if(reg.test(errorType)) {
    history.push(`/errorPage?errorType=${errorType}`)
  }
  
  return Promise.reject({
    success: false,
    errorType,
  })
}

function onResponseError(error) {
  /**
   *  there are three type of errors that would occur
   *  1. api timeout
   *  2. 403 error
   *  3. 500 error
   */
  const { response } = error
  let path = '/errorPage'
  // handle when response timeout
  if(!response) {
    history.push(path)
    return Promise.reject(error)
  }

  const {
    data,
    // status,  // http status
  } = response

  const {
    errorType,
  } = data

  if(errorType) {
    path = `${path}?errorType=${errorType}`
  }

  history.push(path)

  return Promise.reject(error)
}

http.interceptors.response.use(onResponse, onResponseError)

export default http