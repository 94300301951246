import React, { Component } from 'react';
import { withTranslation } from 'react-i18next'
import './WarningBar.scss';

class WarningBar extends Component {
  render() {
    const {
      t,
      text,
      onClick,
    } = this.props

    return (
      <div
      className={ "warning-bar" + (this.props.hidden ? " hidden" : "") }
      onClick={ onClick }>
        <i className="alert-icon aidmics-warning"></i>
        { t(text) }
      </div>
    );
  }
}

export default withTranslation('errorsTranslation')(WarningBar)
