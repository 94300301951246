import React, {
  cloneElement,
  useState
} from "react";
import { withTranslation } from 'react-i18next'
import {
  SwitchTransition,
  CSSTransition,
} from 'react-transition-group'
import './Field.scss'

const Field = (props) => {
  const {
    t,
    type = 'text',
    value,
    placeholder,
    title,
    name,
    maxLength = 100,
    children,
    handleOnEdit = null,
    handleChange = null,
    handleSubmit = null,
  } = props
  const [isDisabled, setIsDisabled] = useState(true)
  const handleEdit = () => {
    setIsDisabled(preState => !preState)
    if(typeof handleOnEdit === 'function') {
      handleOnEdit(name)
    }
  }

  const onSubmit = () => {
    handleEdit()
    if(typeof handleSubmit === 'function') {
      handleSubmit()
    }
  }
  return (
    <div className="input-field">
      {
        title &&
        <p
          className="title"
        >
          { title }
        </p>
      }
      <div className={ `field-wrapper content-wrap ${children ? 'default-padding' : ''}`.trim() }>
        {
          children ?
          cloneElement(children, { isDisabled }) :
          <input
            id={title}
            value={value || ''}
            name={name}
            type={type}
            maxLength={maxLength}
            placeholder={placeholder}
            disabled={isDisabled}
            autoComplete="off"
            onChange={handleChange}
          />
        }
        <SwitchTransition component={null}>
          <CSSTransition
            key={isDisabled ? 'edit' : 'ok'}
            classNames="route"
            timeout={{
              enter: 250,
              exit: 150,
            }}
          >
          {
            isDisabled ?
            <button
              className="button button-edit"
              onClick={handleEdit}
            >
              <i className="aidmics-edit" />
              { t('edit') }
            </button> :
            <button
              className="button button-ok"
              onClick={onSubmit}
            >
              { t('ok') }
            </button>
          }
          </CSSTransition>
        </SwitchTransition>
      </div>
    </div>
  )
}

export default withTranslation('accountTranslation')(Field);
