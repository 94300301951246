import {
  useRef,
  useCallback,
} from 'react'
import { debounce } from 'lodash'
import {
  useAsyncState
} from '@hooks'
import ResizeObserver from 'resize-observer-polyfill'

const useSizeObserve = () => {
  const [currentWidth, setWidth] = useAsyncState(0)
  const [currentHeight, setHeight] = useAsyncState(0)
  const onResize = useRef(entries => {
    const width = entries[0].contentRect.width
    const height = entries[0].contentRect.height
    setWidth(width)
    setHeight(height)
  })

  const debounceOnResize = useCallback(debounce(onResize.current, 0), [])
  const observer = useRef(new ResizeObserver(debounceOnResize))
  return {
    observer: observer.current,
    width: currentWidth,
    height: currentHeight
  }
}

export default useSizeObserve
