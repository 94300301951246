import React from 'react';
import { useSelector } from 'react-redux'
import './Hamburger.scss';

const Hamburger = () => {
  const mobileMenuShown = useSelector(state => state.navbar.mobileMenuShown)

  return (
    <div className={ `hamburger${mobileMenuShown ? " cross" : ""}` } >
      <div className="hamburger-bar hamburger-bar-1"></div>
      <div className="hamburger-bar hamburger-bar-2"></div>
      <div className="hamburger-bar hamburger-bar-3"></div>
    </div>
  )
}

export default Hamburger
