const speciesIcons = {
  SW: "d",
  PO: "i",
  CA: "k",
  BO: "m",
  EQ: "p",
  CN: "r",
  MU: "v",
  OV: "x",
  SA: "s",
  CE: "z",
  LX: "`",
}
const speciesColors = {
  SW: "#bd506a",
  PO: "#be7623",
  CA: "#508443",
  BO: "#2c5878",
  EQ: "#9558a4",
  CN: "#966732",
  MU: "#78b4c3",
  OV: "#5f7033",
  SA: "#61ad8c",
  CE: "#9b6a29",
  LX: "#999999",
  RH: "#827d7d",
}

const getSpeciesIcon = (speciesCode) => {
  return speciesIcons[speciesCode] || speciesIcons["SW"];
}
const getSpeciesColor = (speciesCode) => {
  return speciesColors[speciesCode] || speciesColors["SW"];
}

export {
  getSpeciesIcon,
  getSpeciesColor,
}
