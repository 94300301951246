import {
  useEffect,
  useCallback,
  useMemo,
} from "react"
import moment from 'moment'
import { useTranslation } from "react-i18next"
import { useLocation } from "react-router-dom"
import {
  Modal,
  Form,
  DatePicker,
  Input,
} from 'antd'
import { exportXls } from '@api'
import { useToggle } from '@hooks'
import './DownloadButton.scss'
const { useForm, Item } = Form
const { RangePicker } = DatePicker

const DownloadButton = (props) => {
  const {
    animal_name: propName
  } = props
  const { t } = useTranslation()
  const location = useLocation()
  const search_params = new URLSearchParams(location.search)
  const animal_id = search_params.get('animal_id')
  const [downloadForm] = useForm()

  // Modal
  const [isModalVisible, setModalVisible] = useToggle(false)
  const [isLoading, setIsLoading] = useToggle(false)
  const handleDownload = useCallback(async () => {
    setIsLoading(true)
    try {
      const {
        date_range,
        animal_name,
      } = await downloadForm.validateFields()
      let start_date
      let end_date
      if(date_range) {
        start_date = moment(date_range[0]).format('YYYY-MM-DD')
        end_date = moment(date_range[1]).format('YYYY-MM-DD')
      }
      const postData = {
        animal_id,
        start_date,
        end_date,
        animal_name,
      }
      const res = await exportXls(postData)
      window.location.href = `${process.env.REACT_APP_API_DOMAIN}${res.url}`
    } catch(err) {
      console.error(err)
    } finally {
      setIsLoading(false)
      setModalVisible(false)
      downloadForm.resetFields()
    }
  }, [animal_id])

  useEffect(() => {
    if(!isModalVisible) {
      downloadForm.resetFields()
      return
    }
    downloadForm.setFieldsValue({
      date_range: [
        moment().subtract(6, 'months').startOf('day'),
        moment().endOf('day'),
      ],
      animal_name: propName,
    })
  }, [isModalVisible])

  // Range Picker
  const ranges = useMemo(() => {
    return {
      [t('last_one_year')]: [
        moment().subtract(1, 'years').startOf('day'),
        moment().endOf('day'),
      ],
      [t('last_six_months')]: [
        moment().subtract(6, 'months').startOf('day'),
        moment().endOf('day'),
      ],
      [t('last_one_month')]: [
        moment().subtract(1, 'months').startOf('day'),
        moment().endOf('day'),
      ],
      [t('today')]: [
        moment().startOf('day'),
        moment().endOf('day'),
      ],
    }
  }, [t])
  const disabledDate = useCallback((currentDate) => {
    return currentDate && (
      currentDate > moment().endOf('day') ||
      currentDate < moment(0).startOf('day'))
  }, [])

  return (
    <>
      <div
        className="download-button"
        onClick={setModalVisible}
      >
        <i className="aidmics-md-cloud-download" />
        { t('download') }
      </div>
      <Modal
        title={t('download_filter')}
        visible={isModalVisible}
        centered
        forceRender
        confirmLoading={isLoading}
        onOk={handleDownload}
        onCancel={setModalVisible}
      >
        <Form
          form={downloadForm}
          name="modal_form"
        >
          <Item
            label={t('date_range')}
            name="date_range"
          >
            <RangePicker
              ranges={ranges}
              format="YYYY-MM-DD"
              disabledDate={disabledDate}
            />
          </Item>
          {
            !animal_id &&
            <Item
              label={t('animal_name')}
              name="animal_name"
            >
              <Input />
            </Item>
          }
        </Form>
      </Modal>
    </>
  )
}

export default DownloadButton
