import React, {
  useCallback,
  useEffect,
} from 'react';
import { withTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom';
// components
import { SubmitButton } from '@components';
import { delay } from '@utils'
import logo from '@/assets/images/logo.svg'

const ResetSuccessSubview = (props) => {
  const {
    t,
  } = props;
  const history = useHistory()

  const gotoLogin = useCallback(async () => {
    try {
      await delay(2000)
      history.push('/login')
    } catch(err) {
      console.error(err)
    }
  }, [])

  useEffect(() => {
    gotoLogin()
  }, [])

  return (
    <div className="reset-success">
      <img
        className="logo"
        src={logo}
        alt="logo"
      />
      <div className="title">
        { t('resetSuccessTitle') }
      </div>
      <div className="description">
        { t('resetSuccessDesc') }
      </div>
      <SubmitButton onSubmit={ gotoLogin }>
        { t('login') }
      </SubmitButton>
    </div>
  )
}

export default withTranslation('accountTranslation')(ResetSuccessSubview);
