import React, { useCallback } from 'react';
import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom';
// components
import {
  TextField,
  SubmitButton,
} from '@components';
import logo from '@/assets/images/logo.svg'

const LoginSubview = (props) => {
  const {
    t,
    hidden,
    onChange,
    onSubmit,
  } = props;
  const onKeyPress = useCallback((e) => {
    if(e.key === 'Enter') {
      onSubmit()
    }
  })

  return (
    <div className={ "login" + (hidden ? " hidden" : "") }>
      <img
        className="logo"
        src={logo}
        alt="logo"
      />
      <TextField
        id="account"
        group="login"
        title={ t('account') }
        placeholder={ t('accountPlaceholder') }
        onChange={ onChange }
        onKeyPress={ onKeyPress }
      />
      <TextField
        id="password"
        type="password"
        group="login"
        title={ t('password') }
        placeholder={ t('passwordPlaceholder') }
        onChange={ onChange }
        onKeyPress={ onKeyPress }
      />
      <SubmitButton onSubmit={ onSubmit }>
        { t('login') }
      </SubmitButton>
      <div className="link-wrapper">
        <Link to="/register">
          { t('register') }
        </Link>
      </div>
      <div className="link-wrapper">
        <Link to="/forgot-password">
          { t('forgotPassword') }
        </Link>
      </div>
    </div>
  )
}

export default withTranslation('accountTranslation')(LoginSubview);
