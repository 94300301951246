import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next'
import {
  useHistory,
  useLocation,
} from 'react-router-dom';
import scrollTo from '@utils/Scroll';
import './NavTitle.scss';

const NavTitle = (props) => {
  const {
    type = 'text',
    img,
  } = props;

  const location = useLocation()
  const history = useHistory()
  const { t } = useTranslation('navItemTitles')
  const {
    title,
    shouldShowBack,
    mobileMenuShown,
  } = useSelector(state => {
    return {
      title : state.navbar.headerTitle,
      shouldShowBack: state.navbar.shouldShowBack,
      mobileMenuShown: state.navbar.mobileMenuShown,
    }
  })

  const goBack = useCallback(() => {
    // route should always back to its pre-route
    const reg = /^\/([\w-]+).*\/?$/im
    const matches = location.pathname.match(reg)
    let route = matches[1]
    if(!route) {
      route = '/'
    }
    history.push(`/${route}`)
  }, [history, location])

  const scrollTop = useCallback((e) => {
    e.preventDefault()
    scrollTo(0 , () => {}, 500)
  }, [])

  let navTitleContent;
  if( type === "img" ) {
    navTitleContent = (
      <img src={ img } alt="nav"/>
    )
  } else if( type === "text" ) {
    navTitleContent = t(title)
  }

  return (
    <div className={`nav-title${mobileMenuShown ? " hidden" : ""}`}>
      {
        shouldShowBack && (
        <div
          className="back-button"
          onClick={ goBack }
        >
          <i className="aidmics-md-arrow-dropleft-circle" />
        </div> )
      }
      <span onClick={ scrollTop }>
        { navTitleContent }
      </span>
    </div>
  )
}

export default NavTitle
