import {
  useCallback,
  useEffect,
  useRef,
  useMemo,
  useState,
} from 'react';
import { Pagination } from 'antd'
import {
  SwitchTransition,
  CSSTransition,
} from 'react-transition-group'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import Slider from 'react-slick'
import {
  fetchExperimentList,
  fetchExperimentById,
} from '@redux/actions';
import { getChartData } from '@utils/Numbers'
import {
  usePage,
  useToggle,
  useAsyncState,
  usePositionedPopup,
  useMatchMedia,
  useSizeObserve,
} from '@hooks'
// components
import {
  DataRow,
  Toolbox,
  LineChart,
  Loading,
  StickyWrap,
  PositionedPopup,
  TooltipWrap,
  SimpleFooter,
} from '@components';
import './ExperimentList.scss';

const ExperimentList = (props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const location = useLocation()
  const search_params = new URLSearchParams(location.search)
  const animal_id = search_params.get('animal_id')
  const [isLoading, toggleLoading] = useToggle(true)
  const [target, setTarget] = useAsyncState([])
  const {
    currentPage,
    pageSize,
    handleOnChange,
    pageSizeOptions,
  } = usePage(1, 50, [
    50,
    100,
    150
  ])
  const data = useSelector(state => {
    const {
      animalData,
      experimentList,
    } = state.data
    return animal_id ? animalData : experimentList
  })
  const xAxis = useSelector(state => state.chart.xAxis)
  const yAxis = useSelector(state => state.chart.yAxis)
  const species = useSelector(state => state.species.currentSpecies)

  const [isSticky, setIsSticky] = useState(true)
  const stickyOption = useRef({
    offsetBottom: 0,
    onChange: (affix) => {
      setIsSticky(affix)
    }
  })

  const getExperimentListById = useCallback(async () => {
    try {
      await dispatch(fetchExperimentById(animal_id))
    } catch(err) {
      console.error(err)
    } finally {
      toggleLoading(false)
      setTarget('animalData')
    }
  }, [animal_id])
  const getExperimentList = useCallback(async () => {
    try {
      await dispatch(fetchExperimentList())
    } catch(err) {
      console.error(err)
    } finally {
      toggleLoading(false)
      setTarget('experimentList')
    }
  }, [])

  useEffect(() => {
    if(animal_id) {
      getExperimentListById()
    } else {
      getExperimentList()
    }
  }, [])

  // Search Tool
  const [searchText, setSearchText] = useState()
  const handleOnSearch = useCallback(text => {
    setSearchText(text)
  }, [])
  const filterData = useMemo(() => {
    if(!data?.length) return []
    if(!searchText) return data
    const reg = new RegExp(searchText, 'i')
    return data.filter(d => reg.test(d.animal_name))
  }, [data, searchText])

  // Data Rows
   const slicedData = useMemo(() => {
    const start = (currentPage - 1) * pageSize
    const end = currentPage * pageSize
    return filterData.slice(start, end)
  }, [filterData, currentPage, pageSize])

  const firstRowKeys = useRef([
    'raw_concentration',
    'measured_concentration',
    'motile',
    'progressive',
  ])
  const secondRowKeys = useRef([
    'abnormality',
    'dilution_ratio',
    'total_sperm',
    'total_effective_sperm',
    'total_semen_volume',
    'dose_volume',
    'dose_effective_sperm',
    'extender_per_dose',
    'number_of_doses',
  ])

  // Chart
  const averages = useMemo(() => {
    if(!data) return []
    return getChartData(data, xAxis, yAxis)
  }, [data, xAxis, yAxis])

  const xAxisOptions = useRef([
    'day',
    'week',
    'month',
  ])
  const yAxisOptions = useRef([
    'number_of_doses',
    'raw_concentration',
    'motile',
    'abnormality',
    'progressive',
    'total_effective_sperm',
  ])
  const yAxisUnits = useRef({
    number_of_doses: 'doseUnit',
    raw_concentration: 'concentrationUnit',
    motile: "%",
    abnormality: "%",
    progressive: "%",
    total_effective_sperm: 'spermCountUnit',
  })
  const chartType = useMemo(() => {
    if(yAxis === 'number_of_doses') {
      return 'vertical-bar'
    }
    return 'default'
  }, [yAxis])

  // Popup
  const {
    popupRef,
    isPopupVisible,
    setIsPopupVisible,
    setTransformOrigin,
  } = usePositionedPopup()
  const [popupData, setPopupData] = useState([])
  const [activeIndex, setActiveIndex] = useState(0)

  const sequence = useRef([
    {
      key: 'concentration',
      name: 'concentration',
      unit: 'M/ml'
    },
    {
      key: 'motile',
      name: 'motility_avg',
      unit: '%'
    },
    {
      key: 'progressive',
      name: 'progressive',
      unit: '%'
    },
    {
      key: 'motile_vap',
      name: 'VAP',
      unit: 'µm/s'
    },
    {
      key: 'motile_vcl',
      name: 'VCL',
      unit: 'µm/s'
    },
    {
      key: 'motile_vsl',
      name: 'VSL',
      unit: 'µm/s'
    },
    {
      key: 'motile_str',
      name: 'STR',
      unit: '%'
    },
    {
      key: 'motile_lin',
      name: 'LIN',
      unit: '%'
    },
    {
      key: 'motile_bcf',
      name: 'BCF',
      unit: 'Hz'
    },
    {
      key: 'motile_alh',
      name: 'ALH',
      unit: 'µm'
    },
    {
      key: 'motile_wob',
      name: 'WOB',
      unit: '%'
    },
  ])
  const formatAnalysis = useCallback((item) => {
    const analysis = []
    const kinetics = []

    for(let [i, seq] of sequence.current.entries()) {
      const {
        key,
        name,
        unit,
      } = seq
      let val = item[key]
      let currentUnit = unit
      if(typeof val === 'number') {
        val = Number(val).toFixed(2)
      } else {
        val = 'N/A'
        currentUnit = ''
      }

      const appendData = {
        name,
        unit: currentUnit,
        value: val,
      }

      if(i < 3) {
        analysis.push(appendData)
      } else {
        kinetics.push(appendData)
      }
    }

    return {
      analysis,
      kinetics,
      video: item.video
    }
  }, [])

  const activeAnalysis = useMemo(() => {
    const { analyses } = popupData
    if(!analyses?.length || !analyses[activeIndex]) return null
    
    const currentAnalysis = analyses[activeIndex]
    return formatAnalysis(currentAnalysis)
  }, [popupData, activeIndex, formatAnalysis])

  
  const onPopupClose = useCallback(() => {
    setIsPopupVisible(false)
    setActiveIndex(0)
  }, [])

  const handleVideoClick = useCallback((el, analyses) => {
    setTransformOrigin(el)
    setPopupData(analyses)
    setIsPopupVisible(true)
  }, [])
  const handleVideoTabClick = useCallback((i) => {
    return () => {
      setActiveIndex(i)
    }
  }, [])

  // Modal Carousel
  const [sliderTabs, setSliderTabs] = useState()
  const [sliderPanes, setSliderPanes] = useState()
  const [isMobile, setIsMobile] = useState(window.innerWidth < 744)
  useMatchMedia('(max-width: 743px)', setIsMobile)

  const {
    observer,
    width,
  } = useSizeObserve()

  useEffect(() => {
    observer.observe(document.body)
    return () => {
      observer.disconnect()
    }
  }, [])

  const slideCount = useMemo(() => {
    const analysisLength = popupData.analyses?.length || 0
    const limitedCount = Math.floor(width / 120)
    if(analysisLength < limitedCount) return analysisLength

    return limitedCount < 3 ? 3 : limitedCount
  }, [popupData.analyses, width])

  const handleBeforeSlide = useCallback(() => {
    const dom = document.querySelector('.slider-content')
    dom.scrollTop = 0
  }, [])

  if(isLoading) {
    return (
      <Loading />
    )
  }

  // prevent timeout or non-handled error
  if(!data) {
    return (
      <noscript />
    )
  }

  return (
    <div className="experiment-list wrapper">
      <Toolbox
        target={ target }
        tools={[
          {
            type: "x-axis",
            data: xAxisOptions.current,
          },
          {
            type: "y-axis",
            data: yAxisOptions.current,
          }
        ]}
      />
      <LineChart
        data={ averages }
        xAxis={ xAxis }
        yAxisTitle={ yAxis }
        yAxisUnit={ yAxisUnits.current[yAxis] }
        chartType={ chartType }
      />
      <Toolbox
        target={ target }
        tools={[
          {
            type: "sort",
            data: [
              'animal_name',
              'timestamp',
              'raw_concentration',
              'motile',
              'abnormality',
            ],
          },
          {
            type: "download",
            animal_name: searchText,
          },
          {
            type: "count",
            count: filterData ? filterData.length : 0,
          },
          !animal_id &&
          {
            type: 'search',
            onSearch: handleOnSearch,
          },
        ].filter(Boolean)}
      />
      <SwitchTransition component={null}>
        <CSSTransition
          key={`page-${currentPage}`}
          classNames="route"
          addEndListener={(node, done) => {
            node.addEventListener("transitionend", done, false)
          }}
        >
          <div className="data-rows">
            {
              slicedData.length ?
              slicedData.map(d => {
                const {
                  id,
                } = d
                
                return (
                  <DataRow
                    data={ d }
                    firstRowKeys={ firstRowKeys.current }
                    secondRowKeys={ secondRowKeys.current }
                    showDetail={ true }
                    onVideoBtnClick={handleVideoClick}
                    key={ id }
                  />
                )
              }) :
              <div className="empty">
                { t('noExperiments') }
              </div>
            }
          </div>
        </CSSTransition>
      </SwitchTransition>
      {
        filterData.length > pageSizeOptions.current[0] &&
        <StickyWrap
          options={stickyOption.current}
        >
          <div className="page-wrap">
            <SimpleFooter isVisible={isSticky} />
            <Pagination
              showSizeChanger
              defaultCurrent={currentPage}
              total={filterData.length}
              defaultPageSize={pageSize}
              pageSizeOptions={pageSizeOptions.current}
              onChange={handleOnChange}
            />
          </div>
        </StickyWrap>
      }
      <PositionedPopup
        ref={popupRef}
        isVisible={isPopupVisible}
        onClose={onPopupClose}
      >
        <div className="pop-title">
          <div className="pop-title-item">
            <i
              className={`aidmics-${species}`}
              style={{
                color: 'rgb(189, 80, 106)',
                borderColor: 'rgb(189, 80, 106)'
              }}
            />
            <h2>
              <TooltipWrap triggerNode={popupRef.current}>
                { popupData.animal_name }
              </TooltipWrap>
            </h2>
          </div>
          <span>
            { popupData.date }
          </span>
        </div>
        <div className="pop-content">
          {
            isMobile ?
            <>
              <Slider
                ref={el => setSliderTabs(el)}
                asNavFor={sliderPanes}
                slidesToShow={slideCount}
                swipeToSlide={true}
                focusOnSelect={true}
                centerMode={true}
                arrows={false}
                className="slider-tabs"
              >
                {
                  popupData.analyses?.map((analysis, i) => {
                    return (
                      <div
                        className={`tab-item ${activeIndex === i ? 'active' : ''}`.trim()}
                        key={analysis.id}
                        onClick={handleVideoTabClick(i)}
                      >
                        <span>
                          { `View - ${i + 1}` }
                        </span>
                      </div>
                    )
                  })
                }
              </Slider>
              <Slider
                ref={el => setSliderPanes(el)}
                asNavFor={sliderTabs}
                swipeToSlide={true}
                arrows={false}
                infinite={true}
                className="slider-content"
                beforeChange={handleBeforeSlide}
              >
                {
                  popupData.analyses?.map((analysis, i) => {
                    const {
                      analysis: analysisData,
                      kinetics,
                      video,
                    }  = formatAnalysis(analysis)
                    return (
                      <div
                        className="video-container"
                        key={video}
                      >
                        <div className="video-content">
                          <div className="video-block">
                            <div className="static-radio-container">
                              {
                                video ?
                                <video
                                  className="static-radio video"
                                  controls
                                  autoPlay
                                  muted
                                  loop
                                  playsInline // this is needed for safari autoplay
                                >
                                  <source src={video} type="video/mp4" />
                                  <source src={video} type="video/ogg" />
                                  Your Browser doest not support HTML video.
                                </video> :
                                <p className="no-video">
                                  { t('no_video') }
                                </p>
                              }
                            </div>
                          </div>
                          <div className="data-block analysis">
                            <div className="data-title">
                              <h2>
                                { t('analysis_title') }
                              </h2>
                            </div>
                            <ul className="data-list">
                              {
                                analysisData.map(el => {
                                  const {
                                    name,
                                    unit,
                                    value,
                                  } = el

                                  return (
                                    <li
                                      className="data-item"
                                      key={name}
                                    >
                                      <span className="title">
                                        { t(name) }
                                      </span>
                                      <span className="value">
                                        { value }
                                        <span className="unit">
                                          { unit }
                                        </span>
                                      </span>
                                    </li>
                                  )
                                })
                              }
                            </ul>
                          </div>
                          <div className="data-block kinetics">
                            <div className="data-title">
                              <h2>
                                { t('kinetics_title') }
                              </h2>
                            </div>
                            <ul className="data-list">
                              {
                                kinetics.map(el => {
                                  const {
                                    name,
                                    unit,
                                    value,
                                  } = el
                                  return (
                                    <li
                                      className="data-item"
                                      key={name}
                                    >
                                      <span className="title">
                                        { name }
                                      </span>
                                      <span className="value">
                                        { value }
                                        <span className="unit">
                                          { unit }
                                        </span>
                                      </span>
                                    </li>
                                  )
                                })
                              }
                            </ul>
                          </div>
                        </div>
                      </div>
                    )
                  })
                }
              </Slider>
            </> :
            <>
              <div className="tabs-block">
                <ul className="tabs">
                  {
                    popupData.analyses?.map((analysis, i) => {
                      return (
                        <li
                          className={`tab-item ${activeIndex === i ? 'active' : ''}`.trim()}
                          key={analysis.id}
                          onClick={handleVideoTabClick(i)}
                        >
                          { `View - ${i + 1}` }
                        </li>
                      )
                    })
                  }
                </ul>
              </div>
              <div className="video-container">
                <SwitchTransition
                  mode='out-in'
                  component={null}
                >
                  <CSSTransition
                    key={`video-${activeIndex}`}
                    classNames="fade"
                    addEndListener={(node, done) => {
                      node.addEventListener("transitionend", done, false)
                    }}
                  >
                    <div className="video-content">
                      {
                        activeAnalysis &&
                        <>
                          <div className="video-block">
                            <div className="static-radio-container">
                              {
                                activeAnalysis.video ?
                                <video
                                  className="static-radio video"
                                  controls
                                  autoPlay
                                  muted
                                  loop
                                  playsInline // this is needed for safari autoplay
                                >
                                  <source src={activeAnalysis.video} type="video/mp4" />
                                  <source src={activeAnalysis.video} type="video/ogg" />
                                  Your Browser doest not support HTML video.
                                </video> :
                                <p className="no-video">
                                  { t('no_video') }
                                </p>
                              }
                              
                            </div>
                            
                          </div>
                          <div className="data-block analysis">
                            <div className="data-title">
                              <h2>
                                { t('analysis_title') }
                              </h2>
                            </div>
                            <ul className="data-list">
                              {
                                activeAnalysis.analysis.map(el => {
                                  const {
                                    name,
                                    unit,
                                    value,
                                  } = el

                                  return (
                                    <li
                                      className="data-item"
                                      key={name}
                                    >
                                      <span className="title">
                                        { t(name) }
                                      </span>
                                      <span className="value">
                                        { value }
                                        <span className="unit">
                                          { unit }
                                        </span>
                                      </span>
                                    </li>
                                  )
                                })
                              }
                            </ul>
                          </div>
                          <div className="data-block kinetics">
                            <div className="data-title">
                              <h2>
                                { t('kinetics_title') }
                              </h2>
                            </div>
                            <ul className="data-list">
                              {
                                activeAnalysis.kinetics.map(el => {
                                  const {
                                    name,
                                    unit,
                                    value,
                                  } = el
                                  return (
                                    <li
                                      className="data-item"
                                      key={name}
                                    >
                                      <span className="title">
                                        { name }
                                      </span>
                                      <span className="value">
                                        { value }
                                        <span className="unit">
                                          { unit }
                                        </span>
                                      </span>
                                    </li>
                                  )
                                })
                              }
                            </ul>
                          </div>
                        </>
                      }
                    </div>
                  </CSSTransition>
                </SwitchTransition>
              </div>
            </>
          }
        </div>
      </PositionedPopup>
    </div>
  );
}

export default ExperimentList
