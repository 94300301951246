import React, {
  useCallback,
  useEffect,
} from 'react';
import {
  useHistory,
  useLocation,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next'
import {
  useSelector,
  useDispatch,
} from 'react-redux';
import {
  changeHeaderTitle,
  receiveSpeciesList,
  changeSpecies,
} from '@redux/actions';
import {
  SpeciesSwitch,
  Loading,
} from '@components';
import { getSpecies } from '@api'
import { useToggle } from '@hooks'
import './Species.scss';

const Species = (props) => {
  const { t } = useTranslation(['speciesTranslation'])
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const [isLoading, toggleLoading] = useToggle(true)
  const currentSpecies = useSelector(state => state.species.currentSpecies)
  const allSpecies = useSelector(state => state.species.allSpecies)
  const onSelect = useCallback(() => {
    const {
      state,
    } = location
    let path = "/"
    if(state && state.from) {
      path = state.from
    }
    history.push(path)
  }, [])

  const fetchSpecies = useCallback(async () => {
    try {
      const { species } = await getSpecies()
      dispatch(receiveSpeciesList(species))
      const dataLength = species.length
      if(dataLength === 1) {
        dispatch(changeSpecies(species[0]))
        onSelect()
      }
    } catch(err) {
      console.error(err)
    } finally {
      toggleLoading(false)
    }
  }, [])

  useEffect(() => {
    dispatch(changeHeaderTitle())
    if(currentSpecies === 'WW') {
      fetchSpecies()
    } else {
      toggleLoading(false)
    }
  }, [])

  if(isLoading) {
    return (
      <Loading />
    )
  }

  return (
    <div className="species wrapper">
      <div className="words">
        <h2>
          { t('question') }
        </h2>
        <SpeciesSwitch
          options={ allSpecies }
          onSelect={ onSelect }
        />
      </div>
    </div>
  )
}

export default Species
