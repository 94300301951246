import Axios from '@utils/Axios'
import store from '@redux/store';
import i18n from '@/i18n';
import {
  isEmpty,
  isEmail,
} from '@utils/Validate'
import { errorTypes } from '@utils/Errors'
import { urlEncoder } from '@utils/Network'

const getCommonInfo = () => {
  const state = store.getState()
  const {
    species,
  } = state
  const {
    language
  } = i18n

  return {
    species: species.currentSpecies,
    device_id: "browser",
    locale: language,
  }
}

const getFormData = (data) => {
  const formData = new FormData()
  Object.keys(data).forEach(key => {
    formData.append(key, data[key])
  })
  return formData
}


/** Login  **/
export async function login(data) {
  const {
    account,
    password,
  } = data
  if(isEmpty(account) || isEmpty(password)) {
    return Promise.reject({
      success: false,
      errorType: errorTypes.emptyInfo,
    });
  }
  if(!isEmail(account)) {
    return Promise.reject({
      success: false,
      errorType: errorTypes.accountFormat,
    });
  }

  const postData = getFormData({
    ...data,
    ...getCommonInfo(),
  })
  return Axios({
    url: '/login',
    method: 'POST',
    data: postData,
    "Content-Type": 'application/x-www-form-urlencoded;charset=UTF-8',
  })
}

/** Logout **/
export async function logout() {
  return Axios({
    url: '/logout',
    method: 'POST',
  })
}

/** Delete Account **/
export async function deleteAccount() {
  return Axios({
    url: '/account',
    method: 'DELETE',
  })
}

/** Species List  **/
export async function getSpecies() {
  return Axios({
    url: '/species',
    method: 'GET',
  })
}

/** Account **/
export async function getAccountInfo() {
  return Axios({
    url: '/account',
    method: 'GET',
  })
}

export async function registerAccount(data) {
  const {
    account,
    password,
  } = data

  if(isEmpty(account) || isEmpty(password)) {
    return Promise.reject({
      success: false,
      errorType: errorTypes.emptyInfo,
    });
  }
  if(!isEmail(account)) {
    return Promise.reject({
      success: false,
      errorType: errorTypes.accountFormat,
    });
  }
  if(password.length < 6) {
    return Promise.reject({
      success: false,
      errorType: errorTypes.passwordFormat,
    });
  }

  const postData = getFormData({
    ...data,
    ...getCommonInfo(),
  })
  return Axios({
    url: '/account',
    method: 'POST',
    data: postData,
    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
  })
}

export async function updateAccountInfo(data) {
  const {
    account,
    farmName,
    address,
  } = data
  if(isEmpty(account)
    && isEmpty(farmName)
    && isEmpty(address)) {
      return Promise.resolve()
  }

  // TODO: content-type refactor
  const postData = urlEncoder({
    ...data,
    ...getCommonInfo(),
  })

  return Axios({
    url: '/account',
    method: 'PUT',
    data: postData,
  })
}

/** Password **/
export async function updatePassword(data) {
  const postData = getFormData({
    ...data,
    ...getCommonInfo(),
  })
  return Axios({
    url: '/update_password',
    method: 'POST',
    data: postData,
    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
  })
}

export async function forgetPassword(data) {
  const postData = getFormData({
    ...data,
    locale: getCommonInfo().locale,
  })
  return Axios({
    url: '/forgot_password',
    method: 'POST',
    data: postData,
    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
  })
}

export async function resetPassword(data) {
  const postData = getFormData({
    ...data,
  })
  return Axios({
    url: '/reset_password',
    method: 'POST',
    data: postData,
    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
  })
}

/** Animal **/
export async function getAnimalList() {
  const commonInfo = getCommonInfo()
  if(commonInfo.species === 'WW') {
    commonInfo.species = {}
  }
  return Axios({
    url: '/animals',
    method: 'GET',
    params: {
      ...commonInfo
    },
    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
  })
}

export async function getAnimalById(animal_id) {
  const {
    device_id,
    locale,
  } = getCommonInfo()

  return Axios({
    url: `/animals/${animal_id}`,
    method: 'GET',
    params: {
      device_id,
      locale,
    },
  })
}

export async function updateAnimalById(data, animal_id) {
  const {
    device_id,
    locale,
  } = getCommonInfo()

  return Axios({
    url: `/animals/${animal_id}`,
    method: 'PATCH',
    data: {
      ...data,
      device_id,
      locale,
    },
  })
}

export async function deleteAnimalById(animal_id) {
  const {
    device_id,
    locale,
  } = getCommonInfo()

  return Axios({
    url: `/animals/${animal_id}`,
    method: 'DELETE',
    params: {
      device_id,
      locale,
    },
  })
}

/** Experiment **/
export async function getExperimentList(animal_id) {
  return Axios({
    url: '/experiment',
    method: 'GET',
    params: {
      ...getCommonInfo(),
      animal_id,
    }
  })
}

/** Export Xls **/
export async function exportXls({
  animal_id,
  start_date,
  end_date,
  animal_name,
}) {
  return Axios({
    url: '/export_xls',
    method: 'GET',
    params: {
      ...getCommonInfo(),
      animal_id,
      start_date,
      end_date,
      animal_name,
    }
  })
}

/** Recommendation **/
export async function getRecommendation() {
  return Axios({
    url: '/recommendation',
    method: 'GET',
    params: {
      species: getCommonInfo().species,
    }
  })
}

/** Trend report **/
export async function getTrendList({
  animal_id,
  order_by
}) {
  return Axios({
    url: '/trends',
    method: 'GET',
    params: {
      species: getCommonInfo().species,
      animal_id,
      order_by,
    }
  })
}
