import React, { Component } from 'react';
import { connect } from 'react-redux';
import { changeSpecies } from '@redux/actions';
import { withTranslation } from 'react-i18next'
import { getSpeciesColor } from '@utils/Species';
import './SpeciesSwitch.scss';

class SpeciesSwitch extends Component {
  constructor(props) {
    super(props);
    this.selectSpecies = this.selectSpecies.bind(this);
  }
  selectSpecies(species) {
    return e => {
      const {
        dispatch,
        onSelect,
      } = this.props
      dispatch(changeSpecies(species));
      onSelect();
    }
  }
  render() {
    const {
      t,
      options
    } = this.props
    const additionalClass = "";
    const speciesItems = options.map((s) => {
      const color = getSpeciesColor(s);

      return (
        <div
          className={ "species-item " + additionalClass }
          onClick={ this.selectSpecies(s) }
          key={ s }
        >
          <div
            className={`species-item-icon aidmics-${s}`}
            style={{ color: color, borderColor: color }}
          />
          <div
            className="species-item-name"
            style={{ color: color }}
          >
            { t(s) }
          </div>
        </div>
      );
    });
    return (
      <div className="species-switch">
        { speciesItems }
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    currentSpecies: state.species.currentSpecies
  }
}
const ConnectedSpeciesSwitch = connect(mapStateToProps)(SpeciesSwitch);
export default withTranslation('speciesTranslation')(ConnectedSpeciesSwitch);
